import * as React from "react";
import {
  useNotify,
  useCreate,
  AutocompleteInput,
  Create,
  SimpleForm,
  SelectArrayInput,
  FileField,
  TextInput,
  DateInput,
  required,
  useGetList,
  regex,
} from "react-admin";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useDataProvider } from 'react-admin';

const GameQuizPageSetting = () => {
  return <h3 style={{ marginLeft: 15 }}>Game Quiz Valued Questions</h3>;
};

const ValueQuestionCreate = (props) => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [selectedQuizName, setSelectedQuizName] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [filteredQuizs, setFilteredQuizs] = useState([]);

  const [valueData, setValueData] = useState([]);

  const notify = useNotify();
  const dataProvider = useDataProvider();


  const { data: gameData } = useGetList("quiz/queryGameWithQuiz", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "name", order: "ASC" },
  });

  const { data: attributesData } = useGetList("quiz/queryAttribute", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "name", order: "ASC" },
  });

  let gameObj = [];
  if (gameData) {
    gameObj = gameData.map((obj) => ({
      key: obj.id,
      id: obj.id,
      name: obj.attributes.gameName,
      quizs: [...obj.attributes.quizs],
    }));
  }

  let attributeObj = [];
  if (attributesData) {
    attributeObj = attributesData.map((obj) => ({
      key: obj.id,
      id: obj.id,
      name: obj.attributes.attributeName,
    }));
  }
console.log('attributeObj',attributeObj)
  const handleInputChange = (event, newValue, field) => {
    const gameId = field === "gameId" ? event : selectedGame;
    const quizId = field === "quizId" ? event : selectedQuiz;

    setSelectedGame(gameId);
    setSelectedQuiz(quizId);
    handleFilterChange(gameId, quizId);
  };

  const handleFilterChange = (gameId, quizId) => {
    const search = `filter=${JSON.stringify({ gameId, quizId })}`;
    const newUrl = `${window.location.href.split("?")[0]}?${search}`;
    window.history.replaceState(null, "", newUrl);
  };


  const onError = (error) => {
    notify(`Could not create : ${error.message}`);
  };

  const handleValidate = (value, allValues) => {
    if (value.length > 20) {
      return "Please enter a value with 20 characters or less";
    }
  };

  const onSuccessList = (data) => {
    if (data) {
      const updatedData = Object.values(data)[0].map((item) => ({
        key: item.id,
        id: item.id,
        name: item.value,
        
      }));
      notify("Loading Value Success");
      setValueData(updatedData)
    }
  };

  const handleAttriubteChange = (e) => {
    dataProvider
      .getMany('quiz/queryCharacterValueForAttribute', { attributeId: e })
      .then(onSuccessList)
      .catch(error => {
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    handleSelectedGame(selectedGame);
  }, [selectedGame, selectedQuiz]);

  const handleSelectedGame = (selectedGame) => {
    if (selectedGame) {
      const selectedGameObj = gameObj.find((game) => game.id === selectedGame);
      if (selectedGameObj && selectedGameObj.quizs) {
        const filteredOptions = selectedGameObj.quizs.map((quiz) => ({
          key: quiz.quizId,
          id: quiz.quizId,
          name: quiz.quizName,
        }));
        setFilteredQuizs(filteredOptions);
      } else {
        setFilteredQuizs([]);
      }
    } else {
      setFilteredQuizs([]);
    }
  };

  const handlePathname = () => {
    const url = window.location.hash;
    const urlParams = new URLSearchParams(url.split("?")[1]);
    const filter = JSON.parse(urlParams.get("filter"));
    const gameId = filter.gameId;
    const quizId = filter.quizId;
    const quizName = filter.quizName;

    setSelectedGame(gameId);
    setSelectedQuiz(quizId);
    setSelectedQuizName(quizName)
    handleSelectedGame(gameId);
  };

  useEffect(() => {
    handlePathname();
  }, [gameData]);

  const validateQuizName = [required(), handleValidate];

  const generateOptions = (value) => {
    const optionCount = 6;
    const options = [];
  
    for (let i = 1; i <= optionCount; i++) {
      const questionOptionNameSource = `questionOptions[${i - 1}].questionOptionName`;
      const attributeCharacterValuesSource = `questionOptions[${i - 1}].attributeCharacterValues`;
      const validate = i === 1 || i === 2 ? [required()] : [];
  
      options.push(
        <React.Fragment key={i}>
          <h4>Option{i}</h4>
          <TextInput source={questionOptionNameSource} label="Label" validate={validate} />
          <SelectArrayInput
            source={attributeCharacterValuesSource}
            translateChoice
            label="Value"
            optionValue="name"
            choices={valueData}
          />
        </React.Fragment>
      );
    }
  
    return options;
  };
  

  return (
    <Create {...props} mutationOptions={{ onError }}>
      <GameQuizPageSetting sx={{ marginLeft: 20 }} />
      <SimpleForm>
        <AutocompleteInput
          source="gameName"
          label="Game"
          validate={[required()]}
          style={{ width: 280 }}
          choices={gameObj}
          optionValue="id"
          defaultValue={selectedGame}
          onChange={(event, newValue) => handleInputChange(event, newValue, "gameId")}
        />
        <AutocompleteInput
          source="quizName"
          label="Quiz Name"
          style={{ width: 280 }}
          choices={filteredQuizs}
          optionValue="id"
          defaultValue={selectedQuiz}
          validate={[required()]}
          onChange={(event, newValue) => handleInputChange(event, newValue, "quizId")}
        />
        <TextInput source="questionName" label="Question" validate={[required()]} />
        <AutocompleteInput
          source="attributeId"
          label="Value Attriubte"
          validate={[required()]}
          style={{ width: 280 }}
          choices={attributeObj}
          optionValue="id"
          onChange={handleAttriubteChange}
        />
        {generateOptions("attributeCharacterValues")}

      </SimpleForm>
    </Create>
  );
};
export default ValueQuestionCreate;
