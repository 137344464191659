import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  useGetList,
  AutocompleteInput,
  FormDataConsumer,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

const GameCreateTitle = () => (
  <h3 style={{ marginLeft: 15 }}>Create Calculator Game</h3>
);

const GameCreate = (props) => {
  const { data } = useGetList("FindGameAll", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "name", order: "ASC" },
  });

  const gameList = data?.map((item) => ({
    id: item.id,
    name: item.attributes.gameName,
    gamename: `${item.id}/${item.attributes.gameName}/${item.attributes.gameSEO}`,
  })) || [];

  return (
    <Create {...props}>
      <GameCreateTitle sx={{ marginLeft: 20 }} />
      <SimpleForm>
        <AutocompleteInput
          source="name"
          label="Game"
          style={{ width: 280 }}
          choices={gameList}
          optionValue="gamename"
          validate={[required()]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <AutocompleteInput
              source="seoName"
              label="seoName"
              style={{ width: 280 }}
              validate={[required()]}
              choices={[
                {
                  id: formData?.name?.split("/")[2],
                  name: formData?.name?.split("/")[2]
                },
              ]}
              {...rest}
            />
          )}
        </FormDataConsumer>
        <TextInput
          source="basePrice"
          label="basePrice"
          validate={[required()]}
        />
        <TextInput
          source="metaTitle"
          validate={[required()]}
          label="metaTitle"
        />
        <TextInput
          source="metaKeywords"
          validate={[required()]}
          label="metaKeywords"
        />
        <TextInput
          source="metaDesc"
          validate={[required()]}
          label="metaDesc"
        />
        <RichTextInput
          source="Intro"
          validate={[required()]}
          label="Intro"
        />
      </SimpleForm>
    </Create>
  );
};

export default GameCreate;
