import * as React from "react";
import { useState, useEffect } from "react";
import {
  Create,
  useNotify,
  Form,
  ReferenceInput,
  AutocompleteInput,
  useGetList,
  FileInput,
  FileField,
  SimpleForm,
  TextInput,
  List,
  Datagrid,
  TextField,
  useRecordContext,
  Button,
  useRefresh,
  useCreate
} from "react-admin";
import { Grid } from "@mui/material";
import { useDataProvider } from 'react-admin';
import { Typography, Box } from "@mui/material";
import NumberInputComponent from './NumberInput'


const AttributeSetting = () => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [filteredQuizs, setFilteredQuizs] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [changedItems, setChangedItems] = useState([]);
  const refresh = useRefresh();

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const { data } = useGetList("quiz/queryGameWithQuiz", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "name", order: "ASC" },
  });

  const [create, { isLoading, error }] = useCreate(
    'quiz/modifyAttributePoints',
    {
      data: { items: changedItems },
    },
    {
      onSuccess: (data) => {
        notify("Update Success");
        refresh()
      },
      onError: (error) => {
        notify(`Update Failed: ${error.message}`, { type: "warning" });
      },
    }
  );


  const onSuccessList = (data) => {
    setTableData(data.data);
    notify("Loading Success");
  };

  const handleSelectedGame = (selectedGame) => {
    if (selectedGame) {
      const selectedGameObj = newObj.find((game) => game.id === selectedGame);
      if (selectedGameObj && selectedGameObj.quizs) {
        const filteredOptions = selectedGameObj.quizs.map((quiz) => ({
          key: quiz.quizId,
          id: quiz.quizId,
          name: quiz.quizName,
        }));
        setFilteredQuizs(filteredOptions);
      } else {
        setFilteredQuizs([]);
      }
    } else {
      setFilteredQuizs([]);
    }
  };

  const handlePathname = () => {
    const url = window.location.hash;
    const urlParams = new URLSearchParams(url.split("?")[1]);
    const filter = JSON.parse(urlParams.get("filter"));
    const gameId = filter.gameId;
    const quizId = filter.quizId;
    setSelectedGame(gameId);
    setSelectedQuiz(quizId);
    handleSelectedGame(gameId);
  };

  useEffect(() => {
    handleSelectedGame(selectedGame);
  }, [selectedGame, selectedQuiz]);

  useEffect(() => {
    handlePathname();
  }, [data]);

  useEffect(() => {
    if (selectedQuiz) {
      dataProvider
        .getList('quiz/queryAttribute', { quizId: selectedQuiz })
        .then(onSuccessList)
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }, [selectedQuiz]);

  let newObj = [];
  if (data) {
    newObj = data.map((obj) => ({
      key: obj.id,
      id: obj.id,
      name: obj.attributes.gameName,
      quizs: [...obj.attributes.quizs],
    }));
  }

  if (newObj.length === 0) {
    return null;
  }

  const handleInputChange = (event, newValue, field) => {
    const gameId = field === "gameId" ? event : selectedGame;
    const quizId = field === "quizId" ? event : selectedQuiz;

    setSelectedGame(gameId);
    setSelectedQuiz(quizId);
    handleFilterChange(gameId, quizId);
    setTableData([])
    setChangedItems([])
  };

  const handleFilterChange = (gameId, quizId) => {
    const search = `filter=${JSON.stringify({ gameId, quizId })}`;
    const newUrl = `${window.location.href.split("?")[0]}?${search}`;
    window.history.replaceState(null, "", newUrl);
  };

  const CustomNumberField = ({ source }) => {
    const record = useRecordContext();
    const value = record.attributes[source];
    const isZeroOrFalse = value === 0 || value === false;

    return (
      <Typography variant="body1" color={isZeroOrFalse ? "error" : "inherit"}>
        {value === false ? 'No' : value === true ? 'Yes' : value}
      </Typography>
    );
  };
  const handleSubmitTable = (updatedTableData) => {
    const changedItemsCopy = changedItems.filter((item) => {
      const updatedItem = updatedTableData.find((updated) => updated.id === item.attributeId);
      return updatedItem ? item.points !== Number(updatedItem.attributes.attributePoint) : true;
    });
  
    updatedTableData.forEach((updatedItem) => {
      const originalItem = tableData.find((item) => item.id === updatedItem.id);
      const existingItemIndex = changedItemsCopy.findIndex((item) => item.attributeId === originalItem.attributes.attributeId);
  
      if (originalItem.attributes.attributePoint !== updatedItem.attributes.attributePoint) {
        if (existingItemIndex !== -1) {
          changedItemsCopy[existingItemIndex].points = Number(updatedItem.attributes.attributePoint);
        } else {
          changedItemsCopy.push({
            attributeId: originalItem.attributes.attributeId,
            points: Number(updatedItem.attributes.attributePoint),
          });
        }
      }
    });
  
    setChangedItems(changedItemsCopy);
  };
  

  const handleUpdate = () => {
    console.log('changedItems',changedItems)
    const changedItemsCopy = changedItems.map(item => ({ attributeId: item.attributeId, points: item.points }));
    create('quiz/queryAttribute', { data: { items: changedItemsCopy } });
  };

  return (
    <div style={{ position: "sticky", top: 0, background: "white" }}>
      <h3>Attribute Setting</h3>
      <Form context="form">
        <Grid container>
          <Grid item xs={3}>
            <ReferenceInput source="id" reference="quiz/queryGameWithQuiz" alwaysOn>
              <AutocompleteInput
                source="gameId"
                label="Game"
                style={{ width: 280 }}
                choices={newObj}
                optionValue="id"
                defaultValue={selectedGame}
                onChange={(event, newValue) => handleInputChange(event, newValue, "gameId")}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={4}>
            <ReferenceInput
              source="id"
              reference="quiz/queryGameWithQuiz"
              alwaysOn
              filter={{ gameId: selectedGame ? selectedGame.id : null }}
            >
              <AutocompleteInput
                source="quizId"
                label="Quiz Name"
                style={{ width: 280 }}
                choices={filteredQuizs}
                optionValue="id"
                defaultValue={selectedQuiz}
                onChange={(event, newValue) => handleInputChange(event, newValue, "quizId")}
              />
            </ReferenceInput>
          </Grid>
        </Grid>
      </Form>
      {tableData && tableData.length > 0 ? (
        <Create >
          <SimpleForm toolbar={
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item>
                <Button label="Update" variant="contained" color="primary" onClick={handleUpdate} style={{ fontSize: 14, lineHeight: '28px', margin: '15px' }} />
              </Grid>
            </Grid>
          }>
            <div style={{ marginTop: 20 }}>
              <List source="title" mutationOptions={{ onSuccessList }} pagination={false} actions={null} >
                <Datagrid bulkActionButtons={false}>
                  <TextField source="attributes.attributeName" label="Attributes" />
                  <NumberInputComponent name="attributePoint" label="Points" tableData={tableData} setTableData={handleSubmitTable} />
                  <CustomNumberField source="isEnabled" label="Linked In Character List" />
                  <CustomNumberField source="linkedQuestion" label="Linked Question" />
                </Datagrid>
              </List>
            </div>
          </SimpleForm>
        </Create>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AttributeSetting;
