import * as React from "react";
import { useRecordContext } from "react-admin";
import { Create, SimpleForm, TextInput, required, useGetList } from "react-admin";

const MultiGameParametersCreateCreateTitle = () => {
  const record = useRecordContext();
  return <span>Create Game {record ? `"${record.id}"` : ""}</span>;
};

const MultiGameParametersCreate = (props) => {
  useGetList("games");
  return (
    <Create title={<MultiGameParametersCreateCreateTitle />} {...props}>
     
      <SimpleForm>
        <MultiGameParametersCreateCreateTitle />
        <TextInput source="parameterId" label="ParameterId" validate={[required()]} />
        <TextInput source="parameter" label="Parameter" validate={[required()]} />
        <TextInput source="name" label="Name" validate={[required()]} />
        <TextInput source="label" label="Label" validate={[required()]} />
        <TextInput source="value" label="Value" validate={[required()]} />
        <TextInput source="priority" label="Priority" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};
export default MultiGameParametersCreate;
