const Logo = (props) => {
  const { classNameCustom } = props;

  return (
    <>
      <img src="./logo.png" className={`paLogo ${classNameCustom ? classNameCustom : ""}`} title="meow" alt="test" />
    </>
  );
};

export default Logo;
