import * as React from "react";
import { useRecordContext } from "react-admin";

import { TextInput, Edit, required, TabbedForm, FormTab,  } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

const GameEditTitle = () => {
  const record = useRecordContext();
  console.log('record',record)
  return <h3 style={{ marginLeft: 15 }}>Edit Game {record ? `"${record.attributes.name}"` : "123"}</h3>;
};
const GameEdit = () => {

  return (
    <Edit className="gameEdit">
      <GameEditTitle sx={{ marginLeft: 20 }} />

      <TabbedForm syncWithLocation={false}>
        <FormTab label="Configuration">
          <TextInput source="id" disabled label="id" />
          <TextInput source="attributes.name" disabled label="Game" validate={[required()]} />
          <TextInput source="attributes.seoName" label="seoName" validate={[required()]} />
          <TextInput source="attributes.basePrice" label="basePrice" validate={[required()]} />
          <TextInput source="attributes.metaTitle" label="metaTitle" validate={[required()]} />
          <TextInput source="attributes.metaKeywords" label="metaKeywords" validate={[required()]} />
          <TextInput source="attributes.metaDesc" label="metaDesc" validate={[required()]} />
          <RichTextInput source="attributes.intro" label="Intro"validate={[required()]} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
export default GameEdit;