import * as React from "react";
import { useMediaQuery } from "@mui/material";
import MyPagination from "../MyPagination";
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  useRecordContext,
  BooleanInput,
} from "react-admin";
import StatusSwitcher from "./StatusSwitcher";

const gameFilters = [<TextInput source="q" label="Search" alwaysOn resettable />];

const CountList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List filters={gameFilters} pagination={<MyPagination />} perPage={15}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.title}
          secondaryText={(record) => (
            <>
              <div>
                <b>id:</b> {record.id}
              </div>
              <div>
                <b>Name:</b> {record.attributes.gameName}
              </div>
              <StatusSwitcher name="pageStatus" label="pageStatus" isMobile={true} />
              <StatusSwitcher name="steamCrawlingStatus" label="steamCrawlingStatus" isMobile={true} />
              <StatusSwitcher name="steamChartStatus" label="steamChartStatus" isMobile={true} />
              <StatusSwitcher name="googleCrawlingStatus" label="googleCrawlingStatus" isMobile={true} />
              <StatusSwitcher name="googleChartStatus" label="googleChartStatus" isMobile={true} />
            </>
          )}
          tertiaryText={(record) =>
            record.attributes.updateDateTime &&
            "Last updated: " + new Date(record.attributes.updateDateTime).toLocaleDateString("medium")
          }
          linkType={(record) => (record.attributes.seoName ? "edit" : "show")}
          rowStyle={(record) => ({
            backgroundColor: record.attributes.name ? "white" : "white",
            border: "1px solid #eee",
          })}
        />
      ) : (
        <Datagrid perPage={15} bulkActionButtons={false}>
          <TextField source="id" label="id" />
          <TextField source="attributes.gameName" label="Game" />
          <EditButton />
          <StatusSwitcher name="steamCrawlingStatus" label="Steam Crawling Status" />
          <StatusSwitcher name="steamChartStatus" label="Steam Chart Status" />
          <StatusSwitcher name="googleCrawlingStatus" label="Google Crawling Status" />
          <StatusSwitcher name="googleChartStatus" label="Google Chart Status" />
          <StatusSwitcher name="pageStatus" label="Page Status" />
        </Datagrid>
      )}
    </List>
  );
};
export default CountList;
