import * as React from "react";
import { ImageField, useRedirect,useCreate,useUpdate,useNotify, FileField, ImageInput, SaveButton, AutocompleteInput, useGetList, Toolbar, TextInput, DateInput, Edit, SimpleForm, required, BooleanInput, SelectField } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { useRecordContext } from "ra-core";
import { useState, useEffect } from "react";

const GameEditTitle = () => {
  const record = useRecordContext();
  return <h3 style={{ marginLeft: 15 }}>Edit Game Quiz {record ? `"${record.attributes.gameName}"` : "123"}</h3>;
};

const QuizListEdit = () => {
  const [Data, setData] = useState(true);
  const [imgUrl, setImgUrl] = useState('');
  const redirect = useRedirect();
  const notify = useNotify();

  const { data } = useGetList('FindGameAll', {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'name', order: 'ASC' },
  });

  let arr = []
  let obj = {}
  data?.forEach((i) => {
    arr.push(i)
  })
  for (let key in arr) {
    obj[key] = arr[key];
  };
  let newObj = Object.keys(obj).map(val => ({
    id: obj[val].attributes.gameName,
    name: obj[val].attributes.gameName,
    gameid: obj[val].id,
  }));

  const [createUpload, { isLoading: isUploadLoading, error: uploadError }] = useCreate('quiz/uploadAzureImage', Data, {
    onSuccess: (data) => {
      setImgUrl(data.id.data.fileUrl);
      notify("Upload Success");
    },
    onError: (error) => {
      notify(`Upload Failed: ${error.message}`, { type: "warning" });
    },
  });

  const [createQuiz, { isLoading: isQuizLoading, error: quizError }] = useUpdate('quiz/queryQuiz', data, {
    onSuccess: (data) => {
      notify("Edit Success");
      redirect("/quiz/queryQuiz");
    },
    onError: (error) => {
      notify(`Edit Failed: ${error.message}`, { type: "warning" });
    },
  })
  
  const postSave = (data) => {
    const params = {
      ...data,
      landingPageOGImage: imgUrl,
    };
    createQuiz('quiz/queryQuiz', { data: params });
  };


  const PostEditToolbar = props => (
    <Toolbar {...props} >
      <SaveButton />
    </Toolbar>
  );

  const onError = (error) => {
    notify(`Could not create : ${error.message}`);
  };

  const handleValidate = (value, allValues) => {
    if (value.length > 20) {
      return 'Please enter a value with 20 characters or less';
    }
  };
  

   const handleOgImage = (file) => {
    if (file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        setData(formData);
        createUpload('quiz/uploadAzureImage', { data: formData });
    } else {
      setImgUrl('');
      notify(`Upload Failed : Image size should be no bigger than 1MB.`);
    }
  };
  const validateQuizName = [required(), handleValidate]

  return (
    <Edit className="QuizListEdit" mutationOptions={{ onError }}>
      <>
        <GameEditTitle sx={{ marginLeft: 20 }} />
        <SimpleForm  toolbar={<PostEditToolbar />} onSubmit={postSave}>
        <AutocompleteInput source="attributes.gameId" label="Game" disabled validate={[required()]} style={{ width: 280 }}
            choices={newObj}
            optionValue="gameid"
          />
        <TextInput source="attributes.quizName" label="Quiz Name" validate={validateQuizName} />
        <h4>Start Page</h4>
        <TextInput source="attributes.landingPageSlug" label="Slug" validate={[required()]} />
        <TextInput source="attributes.landingPageH1" label="H1" validate={[required()]}/>
        <TextInput source="attributes.landingPageMetaTitle" label="Meta Title" validate={[required()]} />
        <RichTextInput source="attributes.landingPageMetaDesciption" label="Meta Description" validate={[required()]} />
        <RichTextInput source="attributes.landingPageTopParagraph" label="Top Paragraph" validate={[required()]} />
        <TextInput source="attributes.landingPageH2" label="H2" validate={[required()]}/>
        <RichTextInput source="attributes.landingPageButtomParagrapha" label="Buttom Paragraphs" validate={[required()]} />
        <ImageInput source="attributes.landingPageOGImage"
          label="OG Image"
          maxSize={1000000}
          placeholder={<p>1200 pixels x 630 pixels, no bigger than 1MB</p>}
          onChange={handleOgImage}
          validate={[required()]}
          accept="image/*"
        >
          <FileField source="src" title="title"  />
        </ImageInput>
        {
          imgUrl?(
           <></>
          ): (<ImageField source="attributes.landingPageOGImage" title="title" />)
        }
        <TextInput source="attributes.landingPageOGImageAlt" label="OG Image Alt" validate={[required()]}/>
        <h4>Result Page</h4>
        <TextInput source="attributes.resultPageH1" label="H1" validate={[required()]}/>
        <TextInput source="attributes.resultPageOGTitle" label="OG Title" validate={[required()]} />
        <RichTextInput source="attributes.resultPageTopParagraph" label="Top Paragraph" validate={[required()]} />
      </SimpleForm>
      </>
    </Edit>
  );
};

export default QuizListEdit;
