import * as React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { Admin, Resource, Layout, Menu } from "react-admin";
import Dashboard from "./components/Dashboard";
import MyLayout from "./components/MyLayout";
import MyMenu from "./components/MyMenu";
import GameList from "./components/calculator/GameList";
import CountList from "./components/PlayerCount/CountList";
import CountListEdit from "./components/PlayerCount/CountListEdit";
import GameParameters from "./components/calculator/GameParameters";
import MultiGameParameters from "./components/calculator/MultiGameParameters";
import MultiGameParametersCreate from "./components/calculator/MultiGameParametersCreate";
import CountListCreate from "./components/PlayerCount/CountListCreate";
import MultiGameParametersEdit from "./components/calculator/MultiGameParametersEdit";
import GameCreate from "./components/calculator/GameCreate";
import GameLinkCreate from "./components/calculator/GameLinkCreate";
import GameCharts from "./components/calculator/GameCharts";
import GameLinks from "./components/calculator/GameLinks";
import GameEdit from "./components/calculator/GameEdit";
import GameChartCreate from "./components/calculator/GameChartCreate";
import GameParametersCreate from "./components/calculator/GameParametersCreate";
import GameChartsEdit from "./components/calculator/GameChartsEdit";
import GameParametersEdit from "./components/calculator/GameParametersEdit";
import GameLinksEdit from "./components/calculator/GameLinksEdit";
import authProvider from "./service/authProvider";
import dataProviderPA from "./service/dataProviderPA.js";
import MyLoginPageCustom from "./components/MyLoginPageCustom";
import { QueryClient } from "react-query";
import QuizList from "./components/Quiz/QuizList";
import QuizListCreate from "./components/Quiz/QuizListCreate";
import QuizListEdit from "./components/Quiz/QuizListEdit";
import RandomQuestionList from "./components/Quiz/RandomQuestionList";
import RandomQuestionCreate from "./components/Quiz/RandomQuestionCreate";
import RandomQuestionEdit from "./components/Quiz/RandomQuestionEdit";
import ValueQuestionList from "./components/Quiz/ValueQuestionList";
import CharacterAttributeTable from "./components/Quiz/CharacterAttributeTable";
import CharacterOverViewList from "./components/Quiz/CharacterOverViewList";
import AttributeSetting from "./components/Quiz/AttributeSetting";
import ValueQuestionCreate from "./components/Quiz/ValueQuestionCreate";
import ValueQuestionEdit from "./components/Quiz/ValueQuestionEdit";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10000,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const MyMenuCompo = () => <MyMenu />;
const MyLayoutCompo = (props) => <MyLayout {...props} menu={MyMenuCompo} isloginpage="false" />;

const dataProvider = dataProviderPA(process.env.REACT_APP_SERVER);

window.onload = function () {
  localStorage.removeItem("RaStore.games.listParams");
  localStorage.removeItem("RaStore.parameters.listParams");
  localStorage.removeItem("RaStore.game-charts.listParams");
  localStorage.removeItem("RaStore.game-links.listParams");
};

const App = () => (
  <Admin
    queryClient={queryClient}
    loginPage={MyLoginPageCustom}
    authProvider={authProvider}
    dataProvider={dataProvider}
    layout={MyLayoutCompo}
    dashboard={Dashboard}
    title="Somthing went wrong..."
    disableTelemetry
    requireAuth
  >
    <Resource
      name="games"
      options={{ label: "Value Calculator" }}
      list={GameList}
      edit={GameEdit}
      create={GameCreate}
      intro={"Default game intro"}
    />
    <Resource
      name="gameStat"
      options={{ label: "Game Charts" }}
      list={GameCharts}
      edit={GameChartsEdit}
      create={GameChartCreate}
      intro={"Game Charts intro"}
    />
    <Resource
      name="links"
      options={{ label: "Game Links" }}
      list={GameLinks}
      edit={GameLinksEdit}
      create={GameLinkCreate}
      intro={"Game Links intro"}
    />

    <Resource
      name="paramaters"
      options={{ label: "Games Parameters" }}
      edit={GameParametersEdit}
      list={GameParameters}
      create={GameParametersCreate}
      intro={"Default game intro"}
    />
    <Resource
      name="gameMultiParameter"
      options={{ label: "Game Multi Parameters" }}
      edit={MultiGameParametersEdit}
      list={MultiGameParameters}
      create={MultiGameParametersCreate}
      intro={"Default game intro"}
    />
    <Resource
      name="playerCount/games"
      options={{ label: "Player c" }}
      list={CountList}
      edit={CountListEdit}
      create={CountListCreate}
      intro={"Default game intro"}
    />

    <Resource
      name="quiz/queryQuiz"
      options={{ label: "Game Quiz" }}
      list={QuizList}
      edit={QuizListEdit}
      create={QuizListCreate}
      intro={"Default game intro"}
    />
      <Resource
      name="quiz/queryCharacter"
      options={{ label: "Game Quiz" }}
      list={CharacterOverViewList}
      
      intro={"Default game intro"}
    />
     <Resource
      name="quiz/uploadCharacter"
      options={{ label: "Game Quiz" }}
      list={CharacterAttributeTable}
      // edit={QuizListEdit}
      // create={QuizListCreate}
      intro={"Default game intro"}
    />
     <Resource
      name="quiz/queryAttribute"
      options={{ label: "Game Quiz" }}
      list={AttributeSetting}
      // edit={QuizListEdit}
      // create={QuizListCreate}
      intro={"Default game intro"}
    />

     <Resource
      name="quiz/queryRandomQuestion"
      options={{ label: "Game Quiz" }}
      list={RandomQuestionList}
      edit={RandomQuestionEdit}
      create={RandomQuestionCreate}
      intro={"Default game intro"}
    />
      <Resource
      name="quiz/queryValueQuestion"
      options={{ label: "Game Quiz" }}
      list={ValueQuestionList}
      edit={ValueQuestionEdit}
      create={ValueQuestionCreate}
      intro={"Default game intro"}
    />
    
  </Admin>
);

export default App;
