import * as React from "react";
import { useState, useEffect } from "react";
import { useRecordContext } from "ra-core";
import { BooleanInput, Form, SaveButton, useNotify, useCreate, useRefresh } from "react-admin";

const StatusSwitcher = ({ name, isMobile }) => {
  const record = useRecordContext();
  const resData = record.attributes;
  const notify = useNotify();
  const refresh = useRefresh();

  const _nameFirstLetterUppercase = name.charAt(0).toUpperCase() + name.slice(1);

  const [create, { isLoading, error }] = useCreate(
    `quiz/modifyQuizStatus`,
    {
      data: { quizId: record.id, isEnabled: resData[name] },
    },
    {
      onSuccess: (data) => {
        notify("Update Success");
        refresh();
      },
      onError: (error) => {
        notify(`Update Failed: ${error.message}`, { type: "warning" });
      },
    }
  );

  const handleSwitchChange = (e) => {
    handleSubmit(e.target.checked);
  };

  const handleSubmit = (status) => {
    create(`quiz/modifyQuizStatus`, {
      data: { quizId: record.id, isEnabled: status },
    });
  };

  return (
    <Form>
      <div>
        <BooleanInput
          source={`attributes.${name}`}
          label={isMobile ? name : ""}
          sx={{ flexDirection: "row" }}
          onChange={handleSwitchChange}
          disabled={
            name == "isEnabled" && (resData.attributeCount == 0 || resData.valuedQuestionCount == 0) ? true : false
          }
        />
        <br />
      </div>
    </Form>
  );
};
export default StatusSwitcher;
