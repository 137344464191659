import * as React from "react";
import { useRecordContext } from "ra-core";
import { Form, NumberInput } from "react-admin";

const NumberInputComponent = ({ name, isMobile, tableData, setTableData }) => {
  const record = useRecordContext();
  

  const handleSwitchChange = (e) => {
    const updatedTableData = tableData.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            attributePoint: e.target.value,
          },
        };
      }
      return item;
    });
    setTableData(updatedTableData); 
  }

  return (
    <Form>
      <div >
        <NumberInput source={`attributes.${name}`} label={isMobile ? name : ""}  sx={{ flexDirection: "row" }}
          onChange={handleSwitchChange} />
        <br />

      </div>
    </Form>
  );
};
export default NumberInputComponent;
