import * as React from "react";
import Title from "../common/Title.js";
import { NumberInput, required, TextInput, Edit, SimpleForm,SelectInput, useRecordContext, useEditController } from "react-admin";
import { useState, useEffect } from "react";

const GameSelectComponent = () => {
  const record = useRecordContext();
  const [selectedType, setSelectedType] = useState(record?.attributes?.type || '');
 
  const handleSelectChange = (e) => {
    setSelectedType(e.target.value);
  };
 
  return (
    <>
      <SelectInput
        source="attributes.type"
        label="Type"
        choices={[
          { id: 'select', name: 'select' },
          { id: 'choice', name: 'choice' },
          { id: 'radio', name: 'radio' },
        ]}
        onChange={(event) => handleSelectChange(event)}
        validate={[required()]}
      />
      {selectedType === 'select' && (
        <>
          <TextInput source="attributes.value" label="Value" validate={[required()]} />
          <TextInput source="attributes.step" label="Step" validate={[required()]} />
          <TextInput source="attributes.minVal" label="Min. Value" validate={[required()]} />
          <TextInput source="attributes.maxVal" label="Max. Value" validate={[required()]} />
        </>
      )}
    </>
  );
};
const GameParametersEdit = (props) => {
  const { record, save } = useEditController();

  return (
  <Edit className="GameParametersEdit" {...props} redirect={`/paramaters?filter=${JSON.stringify({ gameId: record.attributes.gameId })}`}>
    <Title text="Parameter:" sx={{ marginLeft: 20 }} />
    <SimpleForm record={record}>
      <TextInput source="id" disabled label="id" />
      <TextInput source="attributes.game" disabled label="Game" />
      <TextInput source="attributes.gameId" disabled label="GameId" />
      <TextInput source="attributes.seoName" disabled label="SeoName" />
      <TextInput source="attributes.label"  label= "Label" />
      <NumberInput source="attributes.priority" label="Priority" />
      <GameSelectComponent />
    
    </SimpleForm>
  </Edit>
  )
}
export default GameParametersEdit;
