import * as React from "react";
import { useState, useEffect } from "react";

import { Form, useNotify, useGetList, List, Datagrid, TextField, useRecordContext, ReferenceInput, AutocompleteInput } from "react-admin";
import MyPagination from "../MyPagination";
import { Typography, Box } from "@mui/material";
import { useDataProvider } from 'react-admin';
import { Grid } from "@mui/material";

const CustomNumberField = ({ source }) => {
  const record = useRecordContext();
  const value = record.attributes[source];
  const isZero = value === 0;

  return (
    <Typography variant="body1" color={isZero ? "error" : "inherit"}>
      {value}
    </Typography>
  );
};

const CharacterOverViewList = () => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [filteredQuizs, setFilteredQuizs] = useState([]);
  const [tableData, setTableData] = useState([]);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const { data } = useGetList("quiz/queryGameWithQuiz", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "name", order: "ASC" },
  });

  const onSuccessList = (data) => {
    setTableData(data.data);
    notify("Loading Success");
  };

  const handleSelectedGame = (selectedGame) => {
    if (selectedGame) {
      const selectedGameObj = newObj.find((game) => game.id === selectedGame);
      if (selectedGameObj && selectedGameObj.quizs) {
        const filteredOptions = selectedGameObj.quizs.map((quiz) => ({
          key: quiz.quizId,
          id: quiz.quizId,
          name: quiz.quizName,
        }));
        setFilteredQuizs(filteredOptions);
      } else {
        setFilteredQuizs([]);
      }
    } else {
      setFilteredQuizs([]);
    }
  };

  const handlePathname = () => {
    const url = window.location.hash;
    const urlParams = new URLSearchParams(url.split("?")[1]);
    const filter = JSON.parse(urlParams.get("filter"));
    const gameId = filter.gameId;
    const quizId = filter.quizId;
    setSelectedGame(gameId);
    setSelectedQuiz(quizId);
    handleSelectedGame(gameId);
  };

  useEffect(() => {
    handleSelectedGame(selectedGame);
  }, [selectedGame, selectedQuiz]);

  useEffect(() => {
    handlePathname();
  }, [data]);

  useEffect(() => {
    if (selectedQuiz) {
      dataProvider
        .getList('quiz/queryCharacter', { quizId: selectedQuiz })
        .then(onSuccessList)
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }, [selectedQuiz]);

  let newObj = [];
  if (data) {
    newObj = data.map((obj) => ({
      key: obj.id,
      id: obj.id,
      name: obj.attributes.gameName,
      quizs: [...obj.attributes.quizs],
    }));
  }

  if (newObj.length === 0) {
    return null;
  }

  const handleInputChange = (event, newValue, field) => {
    const gameId = field === "gameId" ? event : selectedGame;
    const quizId = field === "quizId" ? event : selectedQuiz;

    setSelectedGame(gameId);
    setSelectedQuiz(quizId);
    handleFilterChange(gameId, quizId);
    setTableData([])
  };

  const handleFilterChange = (gameId, quizId) => {
    const search = `filter=${JSON.stringify({ gameId, quizId })}`;
    const newUrl = `${window.location.href.split("?")[0]}?${search}`;
    window.history.replaceState(null, "", newUrl);
  };

  return (
    <>
      <h3>Character Overview</h3>
      <Form context="form">
        <Grid container>
          <Grid item xs={3}>
            <ReferenceInput source="id" reference="quiz/queryGameWithQuiz" alwaysOn>
              <AutocompleteInput
                source="gameId"
                label="Game"
                style={{ width: 280 }}
                choices={newObj}
                optionValue="id"
                defaultValue={selectedGame}
                onChange={(event, newValue) => handleInputChange(event, newValue, "gameId")}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={4}>
            <ReferenceInput
              source="id"
              reference="quiz/queryGameWithQuiz"
              alwaysOn
              filter={{ gameId: selectedGame ? selectedGame.id : null }}
            >
              <AutocompleteInput
                source="quizId"
                label="Quiz Name"
                style={{ width: 280 }}
                choices={filteredQuizs}
                optionValue="id"
                defaultValue={selectedQuiz}
                onChange={(event, newValue) => handleInputChange(event, newValue, "quizId")}
              />
            </ReferenceInput>
          </Grid>
        </Grid>
      </Form>
      {tableData && tableData.length > 0 ? (
        <List perPage={15} pagination={<MyPagination />} >
          <Datagrid bulkActionButtons={false}>
            <TextField source="attributes.characterName" label="Character" />
            <CustomNumberField source="matchAttributeInstance" label="Math Attribute Instance" />
            <CustomNumberField source="matchAttributePoints" label="Math Attribute Points" />
          </Datagrid>
        </List>
      ) : (
        <></>
      )}
    </>
  );
};

export default CharacterOverViewList;
