import * as React from "react";
import { useState, useEffect } from "react";
import {
  Create,
  useNotify,
  Form,
  ReferenceInput,
  AutocompleteInput,
  useGetList,
  FileInput,
  FileField,
  SimpleForm,
  ListBase,
  SaveButton, Toolbar, useRedirect,useCreate
} from "react-admin";
import { Grid } from "@mui/material";
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { useDataProvider } from 'react-admin';

const CharacterAttributeTable = () => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [isBtnabled, setIsBtnabled] = useState(true);

  const [filteredQuizs, setFilteredQuizs] = useState([]);
  const [tableData, setTableData] = useState([]);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const { data } = useGetList("quiz/queryGameWithQuiz", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "name", order: "ASC" },
  });

  const [create, { isLoading, error }] = useCreate(
    'quiz/uploadCharacter',
    {
      data: { items: tableData },
    },
    {
      onSuccess: (data) => {
        console.log('onSuccess',data)
        notify(`Update Success: ${data.id.error.message}`, { type: "info" });
      },
      onError: (error) => {
        console.log('onError',error)
        notify(`Update Failed: ${error.message}`, { type: "warning" });
      },
    }
  );

  const onSuccessList = (data) => {
    setTableData(data.data);
    notify("Loading Success");
  };

  const handleSelectedGame = (selectedGame) => {
    if (selectedGame) {
      const selectedGameObj = newObj.find((game) => game.id === selectedGame);
      if (selectedGameObj && selectedGameObj.quizs) {
        const filteredOptions = selectedGameObj.quizs.map((quiz) => ({
          key: quiz.quizId,
          id: quiz.quizId,
          name: quiz.quizName,
        }));
        setFilteredQuizs(filteredOptions);
      } else {
        setFilteredQuizs([]);
      }
    } else {
      setFilteredQuizs([]);
    }
  };

  const handlePathname = () => {
    const url = window.location.hash;
    const urlParams = new URLSearchParams(url.split("?")[1]);
    const filter = JSON.parse(urlParams.get("filter"));
    const gameId = filter.gameId;
    const quizId = filter.quizId;
    setSelectedGame(gameId);
    setSelectedQuiz(quizId);
    handleSelectedGame(gameId);
    if(quizId){
      setIsBtnabled(false)
    }
  };

  const postSave = (data) => {
    create('quiz/uploadCharacter', { data });
    console.log('postSave', data)
  };
  const PostCreateToolbar = () => {
    const redirect = useRedirect();
    const notify = useNotify();
    return (
      <Toolbar>
        <SaveButton
          disabled={isBtnabled}
          label="SAVE"
          mutationOptions={{
            onSuccess: data => {
              const transformedData = data.id.data.map((item, index) => ({
                id: index + 1,
                ...item,
              }));
              setTableData(transformedData);
              notify(`Upload Success: ${data.id.error.message}`);
            }
          }
          }

        />
      </Toolbar>
    );
  };
  useEffect(() => {
    handleSelectedGame(selectedGame);
  }, [selectedGame, selectedQuiz]);

  useEffect(() => {
    handlePathname();
  }, [data]);

  useEffect(() => {
    if (selectedQuiz) {
      dataProvider
        .getList('quiz/uploadCharacter', { quizId: selectedQuiz })
        .then(onSuccessList)
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }, [selectedQuiz]);

  let newObj = [];
  if (data) {
    newObj = data.map((obj) => ({
      key: obj.id,
      id: obj.id,
      name: obj.attributes.gameName,
      quizs: [...obj.attributes.quizs],
    }));
  }

  if (newObj.length === 0) {
    return null;
  }

  const handleInputChange = (event, newValue, field) => {
    const gameId = field === "gameId" ? event : selectedGame;
    const quizId = field === "quizId" ? event : selectedQuiz;

    setSelectedGame(gameId);
    setSelectedQuiz(quizId);
    handleFilterChange(gameId, quizId);
    setTableData([])
    if(field === "quizId"){
      setIsBtnabled(false)
    }
  };

  const handleFilterChange = (gameId, quizId) => {
    const search = `filter=${JSON.stringify({ gameId, quizId })}`;
    const newUrl = `${window.location.href.split("?")[0]}?${search}`;
    window.history.replaceState(null, "", newUrl);
  };

  return (
    <div style={{ position: "sticky", top: 0, background: "white" }}>
      <h3>Character Table</h3>
      <Form context="form">
        <Grid container>
          <Grid item xs={3}>
            <ReferenceInput source="id" reference="quiz/queryGameWithQuiz" alwaysOn>
              <AutocompleteInput
                source="gameId"
                label="Game"
                style={{ width: 280 }}
                choices={newObj}
                optionValue="id"
                defaultValue={selectedGame}
                onChange={(event, newValue) => handleInputChange(event, newValue, "gameId")}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={4}>
            <ReferenceInput
              source="id"
              reference="quiz/queryGameWithQuiz"
              alwaysOn
              filter={{ gameId: selectedGame ? selectedGame.id : null }}
            >
              <AutocompleteInput
                source="quizId"
                label="Quiz Name"
                style={{ width: 280 }}
                choices={filteredQuizs}
                optionValue="id"
                defaultValue={selectedQuiz}
                onChange={(event, newValue) => handleInputChange(event, newValue, "quizId")}
              />
            </ReferenceInput>
          </Grid>
        </Grid>
      </Form>
      <Create >
        <SimpleForm onSubmit={postSave} toolbar={<PostCreateToolbar />}>
          <FileInput
            source="characterFile"
            label="Upload File"
            placeholder={<p>Please upload a .xlsx file, use ||| to separate multiple values per attribute</p>}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          >
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </Create>
      <div style={{ overflowX: 'auto', marginTop: 20 }}>
        <ListBase mutationOptions={{ onSuccessList }} >
          {tableData && tableData.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(tableData[0])?.map((key) => (
                    <TableCell key={key}>{key ? key : ''}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.map((item, index) => (
                  <TableRow key={index}>
                    {Object.values(item).map((value, index) => (
                      <TableCell key={index}>{value ? value : ''}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <></>
          )}
        </ListBase>
      </div>
    </div>
  );
};

export default CharacterAttributeTable;
