import * as React from "react";
import { useNotify, ArrayInput, FileInput, SaveButton, SimpleFormIterator, useGetList, Toolbar, TextInput, DateInput, Edit, SimpleForm, required, BooleanInput, SelectField } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { useRecordContext } from "ra-core";

const GameEditTitle = () => {
  const record = useRecordContext();
  return <h3 style={{ marginLeft: 15 }}>Edit Random Question</h3>;
};

const RandomQuestionEdit = () => {
  const notify = useNotify();

  const PostEditToolbar = props => (
    <Toolbar {...props} >
      <SaveButton />
    </Toolbar>
  );

  const onError = (error) => {
    notify(`Could not create : ${error.message}`);
  };

  const handleValidate = (value, allValues) => {
    if (value.length > 100) {
      return 'Please enter a value with 100 characters or less';
    }
  };
  

  const validateQuizName = [required(), handleValidate]
  return (
    <Edit className="RandomQuestionEdit" mutationOptions={{ onError }}>
      <>
        <GameEditTitle sx={{ marginLeft: 20 }} />
        <SimpleForm  toolbar={<PostEditToolbar />}>
       
        <TextInput source="attributes.questionName" label="Random Question Name" validate={validateQuizName} />
        <TextInput source="attributes.questionOptions[0]" label="Options1" />
        <TextInput source="attributes.questionOptions[1]" label="Options2"  />
        <TextInput source="attributes.questionOptions[2]" label="Options3"  />
        <TextInput source="attributes.questionOptions[3]" label="Options4"  />
        <TextInput source="attributes.questionOptions[4]" label="Options5"  />
        <TextInput source="attributes.questionOptions[5]" label="Options6"  />
        
        
      </SimpleForm>
      </>
    </Edit>
  );
};

export default RandomQuestionEdit;
