import * as React from "react";
import { useRecordContext } from "react-admin";

import {
  Create,
  SimpleForm,
  TextInput,
  required,
  AutocompleteInput,
  useGetList,
} from "react-admin";

const GameLinkCreateTitle = () => {
  const record = useRecordContext();
  return <span>Create Game {record ? `"${record.gameName}"` : ""}</span>;
};

const GameLinkCreate = (props) => {
  // useGetList("games");
  const { data } = useGetList('games', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  });
  let arr = []
  let obj ={}
  data?.forEach((i)=>{
    arr.push(i)
  })
  for (let key in arr) {
    obj[key] = arr[key];
  };
  let newObj = Object.keys(obj).map(val => ({
        id: obj[val].id,
        name: obj[val].attributes.name,
        gamename:`${obj[val].id}/${obj[val].attributes.name}`,
  }));
  return (
    <Create title={<GameLinkCreateTitle />} {...props}>
      <SimpleForm>
        <TextInput source="id" label="id" disabled />
        <TextInput source="name" label="name" validate={[required()]} />
        <TextInput source="url" label="url" validate={[required()]} />
        <AutocompleteInput  source="gameId" label="GameId" validate={[required()]}  
          choices={newObj} 
          optionValue="gamename"
          style={{width:280}}
        />
      </SimpleForm>
    </Create>
  );
};
export default GameLinkCreate;
