import * as React from "react";
import { useNotify, NumberInput, AutocompleteInput, Create, SimpleForm, SelectArrayInput, TextInput, DateInput, required, useGetList,regex } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

const GameCreatePlayerCount = () => {
  return <h3 style={{ marginLeft: 15 }}>Create PlayerCount Game</h3>;
};

const GameLinkCreate = (props) => {
  useGetList("games");
  const notify = useNotify();
  const { data } = useGetList('FindGameAll', {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'name', order: 'ASC' },
  });
  let arr = []
  let obj = {}
  data?.forEach((i) => {
    arr.push(i)
  })
  for (let key in arr) {
    obj[key] = arr[key];
  };
  let newObj = Object.keys(obj).map(val => ({
    id: obj[val].id,
    name: obj[val].attributes.gameName,
    gamename: `${obj[val].id}/${obj[val].attributes.gameName}/${obj[val].attributes.gameSEO}`,
  }));


  const onError = (error) => {
    notify(`Could not create : ${error.message}`);
  };

  const typeCoefficient = (value, allValues)  => {
    var reg = /^(([1-9]\d*)|([0][.]{1}[0-9]{0,2}[1-9]+)|([1-9]\d*[.]{1}[0-9]+))$/g
    if (!reg.test(parseFloat(value))) {
      return  'Please enter a number greater than 0'
    }
  };
  const typeTotalSubscriber = (value, allValues)  => {
    var reg = /^[0-9]+$/
    if (!reg.test(value)) {
      return  'Please enter a number '
    }
  };


  const validateCoefficient =[required(), typeCoefficient]
  const validateTotalSubscriber =[required(), typeTotalSubscriber]
  
  return (
    <Create {...props} mutationOptions={{ onError }}>
      <GameCreatePlayerCount sx={{ marginLeft: 20 }} />

      <SimpleForm>
        <AutocompleteInput source="gameName" label="Game" validate={[required()]} style={{ width: 280 }}
          choices={newObj}
          optionValue="gamename"
        />
        <SelectArrayInput
          source="platform"
          translateChoice
          choices={[
            { id: 'PC', name: 'PC' },
            { id: 'PS5 / PS4', name: 'PS5 / PS4' },
            { id: 'Xbox One', name: 'Xbox One' },
            { id: 'Xbox Series X/S', name: 'Xbox Series X/S' },
            { id: 'Nintendo Switch', name: 'Nintendo Switch' },
            { id: 'iOS/Android', name: 'iOS/Android' },
          ]}
          validate={[required()]}
          style={{ width: 280 }}
        />
        <SelectArrayInput
          source="genres"
          translateChoice
          choices={[
            { id: 'Action RPG', name: 'Action RPG' },
            { id: 'Battle Royale', name: 'Battle Royale' },
            { id: 'Card Game', name: 'Card Game' },
            { id: 'Digital Pet', name: 'Digital Pet' },
            { id: 'Gacha', name: 'Gacha' },
            { id: 'MMORPG', name: 'MMORPG' },
            { id: 'MOBA', name: 'MOBA' },
            { id: 'Puzzle', name: 'Puzzle' },
            { id: 'PvP', name: 'PvP' },
            { id: 'Sandbox', name: 'Sandbox' },
            { id: 'Shooter', name: 'Shooter' },
            { id: 'Simulation', name: 'Simulation' },
            { id: 'Single-Player', name: 'Single-Player' },
            { id: 'Sports', name: 'Sports' },
            { id: 'Strategy', name: 'Strategy' },
            { id: 'Survival', name: 'Survival' },
          ]}
          validate={[required()]}
          style={{ width: 280 }}
        />
        <DateInput source="released" label="Released" validate={[required()]} style={{ width: 280 }} />
        <TextInput source="developer" label="Developer" validate={[required()]} />
        <TextInput source="totalSubscriber" label="Total Subscriber" validate={validateTotalSubscriber} />
        <TextInput source="steamAppId" label="Steam AppID" />
        <TextInput source="googleQuery" label="Google Query" validate={[required()]} />
        <TextInput source="googleCoefficient" label="Google Coefficient" validate={validateCoefficient}/>
        <RichTextInput source="gameDescripint" label="Game Description" validate={[required()]} />
        {/* <SelectField source="choicesList" choices={choicesList} /> */}
      </SimpleForm>
    </Create>
  );
};
export default GameLinkCreate;
