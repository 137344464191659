import * as React from "react";
import { useNotify, NumberInput, Toolbar, SaveButton, AutocompleteInput, useGetList, SelectArrayInput, TextInput, DateInput, Edit, SimpleForm, required, BooleanInput, SelectField } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { useRecordContext } from "ra-core";

const GameEditTitle = () => {
  const record = useRecordContext();
  return <h3 style={{ marginLeft: 15 }}>Edit Game {record ? `"${record.attributes.gameName}"` : "123"}</h3>;
};

const CountListEdit = () => {
  const notify = useNotify();

  const { data } = useGetList('FindGameAll', {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'name', order: 'ASC' },
  });

  let arr = []
  let obj = {}
  data?.forEach((i) => {
    arr.push(i)
  })
  for (let key in arr) {
    obj[key] = arr[key];
  };
  let newObj = Object.keys(obj).map(val => ({
    id: obj[val].attributes.gameName,
    name: obj[val].attributes.gameName,
    gameid: obj[val].id,
  }));

  const PostEditToolbar = props => (
    <Toolbar {...props} >
      <SaveButton />
    </Toolbar>
  );

  const onError = (error) => {
    notify(`Could not edit : ${error.message}`);
  };
  
  const typeCoefficient = (value, allValues)  => {
    var reg = /^(([1-9]\d*)|([0][.]{1}[0-9]{0,2}[1-9]+)|([1-9]\d*[.]{1}[0-9]+))$/g
    if (!reg.test(parseFloat(value))) {
      return  'Please enter a number greater than 0'
    }
  };
  const typeTotalSubscriber = (value, allValues)  => {  
    var reg = /^[0-9]+$/
    if (!reg.test(value)) {
      return  'Please enter a number '
    }
  };

  const validateCoefficient =[required(), typeCoefficient]
  const validateTotalSubscriber =[required(), typeTotalSubscriber]

  return (
    <Edit className="CountListEdit" mutationOptions={{ onError }}>
      <>
        <GameEditTitle sx={{ marginLeft: 20 }} />
        <SimpleForm toolbar={<PostEditToolbar />} >
          {/* <TextInput disabled source="id" label="id" /> */}
          <AutocompleteInput source="attributes.gameId" label="Game" disabled validate={[required()]} style={{ width: 280 }}
            choices={newObj}
            optionValue="gameid"
          />
          <SelectArrayInput
            source="attributes.platform"
            label="platform"
            translateChoice
            choices={[
              { id: 'PC', name: 'PC' },
              { id: 'PS5 / PS4', name: 'PS5 / PS4' },
              { id: 'Xbox One', name: 'Xbox One' },
              { id: 'Xbox Series X/S', name: 'Xbox Series X/S' },
              { id: 'Nintendo Switch', name: 'Nintendo Switch' },
              { id: 'iOS/Android', name: 'iOS/Android' },
            ]}
            validate={[required()]}
            style={{ width: 280 }}
          />
          <SelectArrayInput
            source="attributes.genres"
            label="genres"
            translateChoice
            choices={[
              { id: 'Action RPG', name: 'Action RPG' },
              { id: 'Battle Royale', name: 'Battle Royale' },
              { id: 'Card Game', name: 'Card Game' },
              { id: 'Digital Pet', name: 'Digital Pet' },
              { id: 'Gacha', name: 'Gacha' },
              { id: 'MMORPG', name: 'MMORPG' },
              { id: 'MOBA', name: 'MOBA' },
              { id: 'Puzzle', name: 'Puzzle' },
              { id: 'PvP', name: 'PvP' },
              { id: 'Sandbox', name: 'Sandbox' },
              { id: 'Shooter', name: 'Shooter' },
              { id: 'Simulation', name: 'Simulation' },
              { id: 'Single-Player', name: 'Single-Player' },
              { id: 'Sports', name: 'Sports' },
              { id: 'Strategy', name: 'Strategy' },
              { id: 'Survival', name: 'Survival' },
            ]}
            validate={[required()]}
            style={{ width: 280 }}
          />
          <DateInput source="attributes.released" label="Released" validate={[required()]} style={{ width: 280 }} />

          <TextInput source="attributes.developer" label="Developer" validate={[required()]} />
          <TextInput source="attributes.totalSubscriber" label="Total Subscriber" validate={validateTotalSubscriber} />
          <TextInput source="attributes.steamAppId" label="Steam AppID" />
          <TextInput source="attributes.googleQuery" label="Google Query" validate={[required()]} />
          <TextInput source="attributes.googleCoefficient" label="Google Coefficient" validate={validateCoefficient} />
          <RichTextInput source="attributes.gameDescripint" label="Game Description" validate={[required()]} />
        </SimpleForm>
      </>
    </Edit>
  );
};

export default CountListEdit;
