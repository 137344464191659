import * as React from "react";
import { TextInput, Edit, SimpleForm } from "react-admin";
import Title from "../common/Title.js";

const GameLinksEdit = () => (
  <Edit className="gameLinksEdit">
    <Title text="Link:" sx={{ marginLeft: 20 }} />
    <SimpleForm>
      <TextInput disabled source="id" label="id" />
      <TextInput source="attributes.name" label="name" />
      <TextInput source="attributes.url" label="url" />
    </SimpleForm>
  </Edit>
);
export default GameLinksEdit;
