import * as React from "react";
import { Card, CardContent } from "@mui/material";
import { Title } from "react-admin";
const Dashboard = () => (
  <Card>
    <Title title="" />
    <CardContent sx={{ margin: "5px 0px 10px" }}>
      <h1>Game Tools CMS</h1>

      <div> Game Acount Value Calculator, Game Quiz and More...</div>
    </CardContent>
  </Card>
);

export default Dashboard;
