import * as React from "react";

import { useRecordContext } from "react-admin";

const Title = ({ text }) => {
  const record = useRecordContext();

  return (
    <h3 style={{ marginLeft: 15 }}>
      Edit {text} {record?.attributes?.name ? record.attributes.name : record.attributes.game || "Page"}
    </h3>
  );
};

export default Title;
