import * as React from "react";
import { useMediaQuery } from "@mui/material";
import MyPagination from "../MyPagination";
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  EditButton,
  BooleanInput,
  useCreate,
  useNotify,
  useRefresh,
  Form,
  ReferenceInput,
  AutocompleteInput,
  useGetList,
  TopToolbar,
  FilterButton,
  ExportButton,
  Button,
} from "react-admin";
import StatusSwitcher from "./StatusSwitcher";
import { useRecordContext } from "ra-core";
import { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { useDataProvider } from "react-admin";

const StatusComponent = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [status, setStatus] = useState(true);

  const [create, { isLoading, error }] = useCreate(
    `quiz/modifyValueQuestionStatus`,
    {
      data: { questionId: record.attributes.questionId, isEnabled: status },
    },
    {
      onSuccess: (data) => {
        notify("Update Success");
        refresh();
      },
      onError: (error) => {
        notify(`Update Failed: ${error.message}`, { type: "warning" });
      },
    }
  );

  const handleSwitchChange = (e) => {
    handleSubmit(e.target.checked);
  };

  const handleSubmit = (status) => {
    setStatus(status);
    create("quiz/modifyValueQuestionStatus", {
      data: { questionId: record.attributes.questionId, isEnabled: status },
    });
  };
  return (
    <BooleanInput source="attributes.isEnabled" label="" sx={{ flexDirection: "row" }} onChange={handleSwitchChange} />
  );
};

const ValueQuestionList = () => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [filteredQuizs, setFilteredQuizs] = useState([]);
  const dataProvider = useDataProvider();

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const notify = useNotify();

  const { data } = useGetList("quiz/queryGameWithQuiz", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "name", order: "ASC" },
  });

  const handleSelectedGame = (selectedGame) => {
    if (selectedGame) {
      const selectedGameObj = newObj.find((game) => game.id === selectedGame);
      if (selectedGameObj && selectedGameObj.quizs) {
        const filteredOptions = selectedGameObj.quizs.map((quiz) => ({
          key: quiz.quizId,
          id: quiz.quizId,
          name: quiz.quizName,
        }));
        setFilteredQuizs(filteredOptions);
      } else {
        setFilteredQuizs([]);
      }
    } else {
      setFilteredQuizs([]);
    }
  };

  const handlePathname = () => {
    const url = window.location.hash;
    const urlParams = new URLSearchParams(url.split("?")[1]);
    const filter = JSON.parse(urlParams.get("filter"));
    const gameId = filter?.gameId;
    const quizId = filter?.quizId;
    setSelectedGame(gameId);
    setSelectedQuiz(quizId);
    handleSelectedGame(gameId);
  };

  useEffect(() => {
    handleSelectedGame(selectedGame);
  }, [selectedGame, selectedQuiz]);

  useEffect(() => {
    handlePathname();
  }, [data]);

  let newObj = [];
  if (data) {
    newObj = data.map((obj) => ({
      key: obj.id,
      id: obj.id,
      name: obj.attributes.gameName,
      quizs: [...obj.attributes.quizs],
    }));
  }

  if (newObj.length === 0) {
    return null;
  }

  const handleCreateBtn = () => {
    // if (!selectedQuiz) {
    //   notify("Loading Value Success");
    //   return;
    // } else {
      const search = `filter=${JSON.stringify({ gameId: selectedGame, quizId: selectedQuiz })}`;
      const newUrl = `${window.location.href.split("?")[0]}/create?${search}`;
      window.location.href = newUrl;
    // }
  };

  const handleInputChange = (event, newValue, field) => {
    const gameId = field === "gameId" ? event : selectedGame;
    const quizId = field === "quizId" ? event : selectedQuiz;

    setSelectedGame(gameId);
    setSelectedQuiz(quizId);
    handleFilterChange(gameId, quizId);
  };

  const handleFilterChange = (gameId, quizId) => {
    const search = `filter=${JSON.stringify({ gameId, quizId })}`;
    const newUrl = `${window.location.href.split("?")[0]}?${search}`;
    window.history.replaceState(null, "", newUrl);
  };

  const CustomTextField = ({ source }) => {
    const record = useRecordContext();
    const value = record.attributes[source];
    const formattedValue = Array.isArray(value) ? value.join(", ") : value;
    const isZeroOrFalse = record.attributes.isEnabledForAttribute;

    if (source === "attributeName") {
      return (
        <Typography variant="body1" color={!isZeroOrFalse ? "error" : "inherit"}>
          {formattedValue}
        </Typography>
      );
    }

    return <Typography variant="body1">{formattedValue}</Typography>;
  };

  const searchFilter = [
    <ReferenceInput source="gameId" reference="quiz/queryGameWithQuiz" alwaysOn>
      <AutocompleteInput
        source="gameId"
        label="Game"
        style={{ width: 280 }}
        choices={newObj}
        optionValue="id"
        defaultValue={selectedGame}
        onChange={(event, newValue) => handleInputChange(event, newValue, "gameId")}
      />
    </ReferenceInput>
    ,
    <ReferenceInput
      source="quizId"
      reference="quiz/queryGameWithQuiz"
      alwaysOn
      filter={{ gameId: selectedGame ? selectedGame.id : null }}
      name="quiz"
    >
      <AutocompleteInput
        source="quizId"
        label="Quiz Name"
        style={{ width: 280 }}
        choices={filteredQuizs}
        optionValue="id"
        defaultValue={selectedQuiz}
        onChange={(event, newValue) => handleInputChange(event, newValue, "quizId")}
      />
    </ReferenceInput>,
  ];


  const ListActions = () => (
    <TopToolbar>
      <FilterButton />
      <Button label="Create" onClick={handleCreateBtn}  />
      {/* <Button label="Create" onClick={handleCreateBtn} disabled={!selectedQuiz} /> */}

      <ExportButton />
    </TopToolbar>
  );
  return (
    <>
      <List pagination={<MyPagination />} perPage={15} filters={searchFilter} actions={<ListActions />}>
        {isSmall ? (
          <SimpleList
            primaryText={(record) => record.title}
            secondaryText={(record) => (
              <>
                <div>
                  <b>Question:</b> {record.attributes.questionName}
                </div>
                <div>
                  <b>Attribute:</b> {record.attributes.attributeName}
                </div>
                <div>
                  <b>Missing Value:</b> {Array.isArray(record.attributes.missValue) ? record.attributes.missValue.join(", ") : record.attributes.missValue}
                </div>
                <StatusSwitcher name="pageStatus" label="pageStatus" isMobile={true} />
              </>
            )}
            tertiaryText={(record) =>
              record.attributes.updateDateTime &&
              "Last updated: " + new Date(record.attributes.updateDateTime).toLocaleDateString("medium")
            }
            linkType={(record) => (record.attributes.seoName ? "edit" : "show")}
            rowStyle={(record) => ({
              backgroundColor: record.attributes.name ? "white" : "white",
              border: "1px solid #eee",
            })}
          />
        ) : (
          <Datagrid perPage={15} bulkActionButtons={false}>
            <TextField source="attributes.questionName" label="Question" />
            <CustomTextField source="attributeName" label="Attribute" />
            <CustomTextField source="missValue" label="Missing Value" />
            <Form label="Status">
              <StatusComponent />
            </Form>
            <EditButton />
          </Datagrid>
        )}
      </List>
    </>
  );
};
export default ValueQuestionList;
