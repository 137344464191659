import * as React from "react";
import { AppBar } from "react-admin";
import Typography from "@mui/material/Typography";
import Logo from "./Logo";

const MyAppBar = (props) => {
  const userName = localStorage.getItem("username");
  return (
    <>
      <AppBar
        sx={{
          "& .RaAppBar-title": {
            flex: 1,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          },
        }}
        {...props}
      >
        <Typography {...props} variant="h6" color="inherit" className="react-admin-title" />
        <div className="paAppBarWrapper">
          <Logo />
          <div className="paAppBarLogoName">Playerauctions</div>
        </div>
        <div className="paAppBarUserName">{userName}</div>
      </AppBar>
    </>
  );
};
export default MyAppBar;
