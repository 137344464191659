import * as React from "react";
import {
  useNotify,
  useCreate,
  AutocompleteInput,
  Edit,
  SimpleForm,
  SelectArrayInput,
  FileField,
  TextInput,
  DateInput,
  required,
  useGetList,
  regex,
} from "react-admin";
import { useState, useEffect } from "react";
import { useDataProvider } from "react-admin";

const GameQuizPageSetting = () => {
  return <h3 style={{ marginLeft: 15 }}>Game Quiz Valued Questions</h3>;
};

const ValueQuestionEdit = (props) => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [selectedQuizName, setSelectedQuizName] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState([]);
  const [filteredQuizs, setFilteredQuizs] = useState([]);
  const [editDetail, setDditDetail] = useState({});
  const [initialized, setInitialized] = useState(false);
  const [valueData, setValueData] = useState([]);

  const path = window.location.href.split('/')
  const id = path[path.length-1]

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const { data: gameData } = useGetList("quiz/queryGameWithQuiz", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "name", order: "ASC" },
  });

  let gameObj = [];
  if (gameData) {
    gameObj = gameData.map((obj) => ({
      key: obj.id,
      id: obj.id,
      name: obj.attributes.gameName,
      quizs: [...obj.attributes.quizs],
    }));
  }

  const handleAttriubte = (arr) => {
    let attributeObj = [];
    if (arr) {
      attributeObj = arr.map((obj) => ({
        key: obj.id,
        id: obj.id,
        name: obj.attributes.attributeName,
      }));
    }
    setSelectedAttribute(attributeObj)
  }

  const onError = (error) => {
    notify(`Could not create : ${error.message}`);
  };

  const onSuccessList = (data) => {
    if (data) {
      const updatedData = Object.values(data)[0].map((item) => ({
        key: item.id,
        id: item.id,
        name: item.value,
      }));
      notify("Loading Value Success");
      setValueData(updatedData);
    }

  };

  const handleAttriubteChange = (e) => {
    dataProvider
      .getMany("quiz/queryCharacterValueForAttribute", { attributeId: e })
      .then(onSuccessList)
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (!initialized) {
      dataProvider
        .getOne('quiz/queryValueQuestion', { id: id })
        .then((response) => {
          handleEditDetail(response.data);
          setInitialized(true);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, [dataProvider, id, initialized]);

  const handleEditDetail = (data) => {
    console.log('handleEditDetail', data)
    handleSelectedGame(data.attributes.gameId)
    setSelectedQuiz(data.attributes.quizId)
    handleAttriubteChange(data.attributes.attributeId)
    dataProvider
      .getList('quiz/queryAttribute', {
        quizId: data.attributes.quizId,
        IsEnable: true
      })
      .then((data) => { handleAttriubte(data.data) })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const handleSelectedGame = (selectedGame) => {
    if (selectedGame) {
      const selectedGameObj = gameObj.find((game) => game.id === selectedGame);
      if (selectedGameObj && selectedGameObj.quizs) {
        const filteredOptions = selectedGameObj.quizs.map((quiz) => ({
          key: quiz.quizId,
          id: quiz.quizId,
          name: quiz.quizName,
        }));
        setFilteredQuizs(filteredOptions);
      } else {
        setFilteredQuizs([]);
      }
    } else {
      setFilteredQuizs([]);
    }
  };


  const generateQuestionOptionsInputs = (paramName) => {
    const questionOptionsInputs = [];
    for (let i = 0; i < 6; i++) {
      const questionOptionIndex = `${paramName}[${i}]`;
      questionOptionsInputs.push(
        <React.Fragment key={i}>
          <h4>Option{i + 1}</h4>
          <TextInput
            source={`${questionOptionIndex}.questionOptionName`}
            label="Label"
            validate={i < 2 ? [required()] : []}
          />
          <SelectArrayInput
            source={`${questionOptionIndex}.attributeCharacterValues`}
            translateChoice
            label="Value"
            validate={i < 2 ? [required()] : []}
            optionValue="name"
            choices={valueData}
          />
        </React.Fragment>
      );
    }
    return questionOptionsInputs;
  };


  return (
    <Edit className="ValueQuestionEdit" mutationOptions={{ onError }}>
      <GameQuizPageSetting sx={{ marginLeft: 20 }} />
      <SimpleForm>
        <AutocompleteInput
          source="attributes.gameId"
          label="Game"
          validate={[required()]}
          style={{ width: 280 }}
          choices={gameObj}
          optionValue="id"
          defaultValue={selectedGame}
          disabled
        />
        <AutocompleteInput
          source="attributes.quizId"
          label="Quiz Name"
          style={{ width: 280 }}
          choices={filteredQuizs}
          optionValue="id"
          defaultValue={selectedQuiz}
          validate={[required()]}
          disabled
        />
        <TextInput source="attributes.questionName" label="Question" validate={[required()]} />
        <AutocompleteInput
          source="attributes.attributeId"
          label="Value Attriubte"
          validate={[required()]}
          style={{ width: 280 }}
          choices={selectedAttribute}
          optionValue="id"
          onChange={handleAttriubteChange}
        />
        {generateQuestionOptionsInputs("attributes.questionOptions")}
      </SimpleForm>
    </Edit>
  );
};
export default ValueQuestionEdit;
