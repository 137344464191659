import * as React from "react";
import { useRedirect, useNotify, useCreate, AutocompleteInput, Create, SimpleForm, ImageInput, FileField, TextInput, DateInput, required, useGetList, regex } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { useState, useEffect } from "react";

const GameQuizPageSetting = () => {
  return <h3 style={{ marginLeft: 15 }}>Quiz Setting</h3>;
};

const GameLinkCreate = (props) => {
  const [Data, setData] = useState(true);
  const [imgUrl, setImgUrl] = useState('');
  const redirect = useRedirect();
  const notify = useNotify();

  useGetList("games");
  const { data } = useGetList('FindGameAll', {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'name', order: 'ASC' },
  });
  let arr = []
  let obj = {}
  data?.forEach((i) => {
    arr.push(i)
  })
  for (let key in arr) {
    obj[key] = arr[key];
  };
  let newObj = Object.keys(obj).map(val => ({
    id: obj[val].id,
    name: obj[val].attributes.gameName,
    gamename: `${obj[val].id}/${obj[val].attributes.gameName}/${obj[val].attributes.gameSEO}`,
  }));

  const [createUpload, { isLoading: isUploadLoading, error: uploadError }] = useCreate('quiz/uploadAzureImage', Data, {
    onSuccess: (data) => {
      setImgUrl(data.id.data.fileUrl);
      notify("Upload Success");
    },
    onError: (error) => {
      notify(`Upload Failed: ${error.message}`, { type: "warning" });
    },
  });

  const [createQuiz, { isLoading: isQuizLoading, error: quizError }] = useCreate('quiz/queryQuiz', data, {
    onSuccess: (data) => {
      notify("Create Success");
      redirect("/quiz/queryQuiz");
    },
    onError: (error) => {
      notify(`Create Failed: ${error.message}`, { type: "warning" });
    },
  })

  const postSave = (data) => {
    const params = {
      ...data,
      landingPageOGImage: imgUrl,
    };
    createQuiz('quiz/queryQuiz', { data: params });
  };


  const onError = (error) => {
    notify(`Could not create : ${error.message}`);
  };

  const handleValidate = (value, allValues) => {
    if (value.length > 20) {
      return 'Please enter a value with 20 characters or less';
    }
  };

  const handleOgImage = (file) => {
    if (file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        setData(formData);
        createUpload('quiz/uploadAzureImage', { data: formData });
    } else {
      setImgUrl('');
      notify(`Upload Failed : Image size should be no bigger than 1MB.`);
    }
  };
  

  const validateQuizName = [required(), handleValidate]
  return (
    <Create {...props} mutationOptions={{ onError }}>
      <GameQuizPageSetting sx={{ marginLeft: 20 }} />
      <SimpleForm onSubmit={postSave}>
        <AutocompleteInput source="gameName" label="Game" validate={[required()]} style={{ width: 280 }}
          choices={newObj}
          optionValue="gamename"
        />
        <TextInput source="quizName" label="Quiz Name" validate={validateQuizName} />
        <h4>Start Page</h4>
        <TextInput source="landingPageSlug" label="Slug" validate={[required()]} />
        <TextInput source="landingPageH1" label="H1" validate={[required()]}/>
        <TextInput source="landingPageMetaTitle" label="Meta Title" validate={[required()]} />
        <RichTextInput source="landingPageMetaDesciption" label="Meta Description" validate={[required()]} />
        <RichTextInput source="landingPageTopParagraph" label="Top Paragraph" validate={[required()]} />
        <TextInput source="landingPageH2" label="H2" validate={[required()]}/>
        <RichTextInput source="landingPageButtomParagrapha" label="Buttom Paragraphs" validate={[required()]} />
        <ImageInput source="landingPageOGImage"
          label="OG Image"
          maxSize={1000000}
          placeholder={<p>1200 pixels x 630 pixels, no bigger than 1MB</p>}
          onChange={handleOgImage}
          validate={[required()]}
          accept="image/*"
        >
          <FileField source="src" title="title" />
        </ImageInput>
        <a href={imgUrl} target="_blank" rel="noopener noreferrer" style={{ color: '#1976d2', fontSize: '0.875rem' }}>
          {imgUrl}
        </a>
        <TextInput source="landingPageOGImageAlt" label="OG Image Alt" validate={[required()]} />
        <h4>Result Page</h4>
        <TextInput source="resultPageH1" label="H1" validate={[required()]} />
        <TextInput source="resultPageOGTitle" label="OG Title" validate={[required()]} />
        <RichTextInput source="resultPageTopParagraph" label="Top Paragraph" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};
export default GameLinkCreate;
