import * as React from "react";
import { useRecordContext } from "react-admin";
import { Create, SimpleForm, TextInput, required, useGetList,  AutocompleteInput, } from "react-admin";

const GameChartCreateTitle = () => {
  const record = useRecordContext();
  return <span>Create Game {record ? `"${record.gameName}"` : ""}</span>;
};

const GameChartCreate = props => {
  const { data } = useGetList('games', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  });

  let arr = []
  let obj ={}

  data?.forEach((i)=>{
    arr.push(i)
  })

  for (let key in arr) {
    obj[key] = arr[key];
  };

  let newObj = Object.keys(obj).map(val => ({
        id: obj[val].id,
        name: obj[val].attributes.name,
        gamename:`${obj[val].id}/${obj[val].attributes.name}`,
  }));

  return (

    <Create title={<GameChartCreateTitle />} {...props}>
      <SimpleForm>
        <TextInput source="id" label="id" disabled />
        <AutocompleteInput  source="gameId" label="GameId" validate={[required()]}  
          choices={newObj}
          optionValue="gamename"
          style={{width:280}}
        />
        <TextInput source="month" label="Month" validate={[required()]} />
        <TextInput source="aov" label="AOV" validate={[required()]} />
      </SimpleForm>
    </Create>
  );

};

export default GameChartCreate;