import * as React from "react";
import Title from "../common/Title.js";

import { TextInput, Edit, SimpleForm } from "react-admin";

const MultiGameParameters = () => (
  <Edit className="MultiGameParameters">
    <Title text="Multi-Parameter:" sx={{ marginLeft: 20 }} />

    <SimpleForm>
      <TextInput source="id" disabled label="id" />
      <TextInput source="attributes.parameterId" label="ParameterId" />
      <TextInput source="attributes.parameter" label="Parameter"  />
      <TextInput source="attributes.name" label="Name" />
      <TextInput source="attributes.label" label="Label" />
      <TextInput source="attributes.value" label="Value" />
      <TextInput source="attributes.priority" label="Priority" />
    </SimpleForm>
  </Edit>
);
export default MultiGameParameters;
