import * as React from 'react';
import { useState } from 'react';
import { List, ListItem, ListItemText, Collapse, ListItemIcon } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InboxIcon from '@mui/icons-material/MoveToInbox';

export const SubMenu = (props) => {
    const { primaryText, leftIcon, children, ...rest } = props;

    const [open, setOpen] = React.useState(true);

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <React.Fragment>
            <ListItem
                dense
                button
                onClick={handleToggle}
                sx={{
                    paddingLeft: '1rem',
                    color: 'rgba(0, 0, 0, 0.54)',
                }}
            >

              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={primaryText}  />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    sx={open ? {
                        paddingLeft: '25px',
                        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                    } : {
                        paddingLeft: 0,
                        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                    }}
                >
                    {children}
                </List>
            </Collapse>
        </React.Fragment>
    )
}


export default SubMenu;
