import * as React from "react";
import { TextInput, Edit, SimpleForm, NumberInput } from "react-admin";
import Title from "../common/Title.js";

const GameChartsEdit = () => (
  <Edit className="GameChartsEdit">
    <Title text="Chart:" sx={{ marginLeft: 20 }} />
    <SimpleForm>
      <TextInput disabled source="id" label="id" />
      <NumberInput disabled source="attributes.gameId" label="GameId" />
      <TextInput source="attributes.month" label="Month" />
      <NumberInput source="attributes.aov" label="AOV" />
    </SimpleForm>
  </Edit>
);
export default GameChartsEdit;
