import * as React from "react";

import { List, Datagrid, TextField, EditButton, useRecordContext } from "react-admin";
import MyPagination from "../MyPagination";
import { ReferenceField } from "react-admin";

const LinkTitle = () => {
  const record = useRecordContext();
  return <span>Post {record ? `"${record.title}"` : ""}</span>;
};
const GameLinks = () => {
  return (
    <List title={<LinkTitle />} perPage={50} pagination={<MyPagination />}>
      <h3 style={{ marginLeft: "10px", marginTop: "-38px", width: "200px" }}>Game Links</h3>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label="LinkId" />
        <TextField source="attributes.name" label="name" />
        <TextField source="attributes.url" label="url" />
        <ReferenceField label="Game (GameId)" source="attributes.gameId" reference="games" sortByOrder="DESC">
          <TextField source="attributes.name" />
          {" ("}
          <TextField source="id" />
          {")"}
        </ReferenceField>
        {/* <TextField source="attributes.gameId" label="GameId" /> */}
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default GameLinks;
