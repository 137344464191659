import * as React from "react";
import { useNotify, Create, SimpleForm,  TextInput, required, useGetList } from "react-admin";

const GameQuizPageSetting = () => {
  return <h3 style={{ marginLeft: 15 }}>Game Quiz Random Questions</h3>;
};

const RandomQuestionCreate = (props) => {

  useGetList("games");
  const notify = useNotify();

  const onError = (error) => {
    notify(`Could not create : ${error.message}`);
  };

  const handleValidate = (value, allValues) => {
    if (value.length > 100) {
      return 'Please enter a value with 100 characters or less';
    }
  };

  const validateQuizName = [required(), handleValidate]

  return (
    <Create {...props} mutationOptions={{ onError }}>
      <GameQuizPageSetting sx={{ marginLeft: 20 }} />

      <SimpleForm>
        <TextInput source="questionName" label="Question" validate={validateQuizName} />
        <TextInput source="questionOptions1" label="Options1" />
        <TextInput source="questionOptions2" label="Options2"  />
        <TextInput source="questionOptions3" label="Options3"  />
        <TextInput source="questionOptions4" label="Options4"  />
        <TextInput source="questionOptions5" label="Options5"  />
        <TextInput source="questionOptions6" label="Options6"  />
      </SimpleForm>
    </Create>
  );
};
export default RandomQuestionCreate;
