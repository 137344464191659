import * as React from "react";
import { useState, useEffect } from "react";
import { useRecordContext } from "ra-core";
import { BooleanInput, Form, SaveButton, useNotify, useCreate, useRefresh } from "react-admin";

const StatusSwitcher = ({ name, isMobile }) => {
  const record = useRecordContext();
  const resData = record.attributes
  const notify = useNotify();
  const refresh = useRefresh();

  const _nameFirstLetterUppercase = name.charAt(0).toUpperCase() + name.slice(1);

  const [create, { isLoading, error }] = useCreate(
    `playerCount/games/edit${_nameFirstLetterUppercase}`,
    {
      data: { gameId: record.id, [name]: !resData[name] },
    },
    {
      onSuccess: (data) => {
        notify("Update Success");
        refresh()
      },
      onError: (error) => {
        notify(`Update Failed: ${error.message}`, { type: "warning" });
      },
    }
  );

  const handleSwitchChange = (e) => {
  
    handleSubmit(e.target.checked)
  }

  const handleSubmit = (status) => {
    create(`playerCount/games/edit${_nameFirstLetterUppercase}`, {
      data: { gameId: record.id, [name]: status },
    },);
  };

  

  useEffect(() => {
  }, [])

  return (
    <Form>
      <div >
        <BooleanInput
          source={`attributes.${name}`}
          label={isMobile ? name : ""}
          sx={{ flexDirection: "row" }}
          onChange={handleSwitchChange}
          disabled={
            name == 'steamCrawlingStatus' && !resData.steamAppIdFlag? true:
            name == 'steamChartStatus' && (!resData.steamCrawlingStatus || !resData.steamAppIdFlag || record.attributes.steam7DayIncomplete)? true:
            name == 'googleChartStatus' && (!resData.googleCrawlingStatus || record.attributes.google7DayIncomplete)? true :
            
            false
          }
        />
        {
          name == 'steamCrawlingStatus' && resData.steam7DayIncomplete ||
            name == 'googleCrawlingStatus' && resData.google7DayIncomplete ?
            <span style={{ color: "#e21212" }}>7d’s data is incomplete</span> :
            <></>
        }
        {
          name == 'steamCrawlingStatus' && !resData.steamAppIdFlag ?
            <span style={{ color: "#e21212" }}>AppID Unavailable</span> :
            <></>
        }
        {
          name == 'pageStatus' && !resData.steamChartStatus && !resData.googleChartStatus ?
            <span style={{ color: "#e21212" }}>No charts on this page</span> :
            <></>
        }
        <br />

      </div>
    </Form>
  );
};
export default StatusSwitcher;
