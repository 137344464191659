import * as React from "react";
import MyPagination from "../MyPagination";
import { 
  List, 
  Datagrid, 
  TextField, 
  EditButton,
  ExportButton,
  TopToolbar,
  Button, } from "react-admin";
import { ReferenceField } from "react-admin";
import SingleRelationRecord from "../common/SingleRelationRecord.js";
import LinkIcon from "../icons/LinkIcon";
import { useState, useEffect } from "react";

const ListActions = () => {
  const handleCreateClick = () => {
    const search = `${window.location.href.split('?')[1]}`;
    const newUrl = `#/paramaters/create${search ? `?${search}` : ''}`;
    window.location.assign(newUrl);
  };

  return (
    <TopToolbar>
      <Button color="primary" onClick={handleCreateClick}>
        Create
      </Button>
      <ExportButton />
    </TopToolbar>
  );
};
const GameParameters = () => {

  return (
    <>
      <List perPage={15} pagination={<MyPagination />} actions={<ListActions/>}>
        <h3 style={{ marginLeft: "10px", marginTop: "-38px", width: "200px" }}>Game Parameters</h3>

        <Datagrid bulkActionButtons={false}>
          <TextField source="id" disabled label="ParameterId" />
          <TextField source="attributes.label" label="Label" />
          <TextField source="attributes.seoName" label="SeoName" />
          <TextField source="attributes.value" label="Value" />
          <TextField source="attributes.priority" label="Priority" />
          <TextField source="attributes.type" label="Type" />
          <TextField source="attributes.step" label="Step" />
          <TextField source="attributes.minVal" label="Min. Value" />
          <TextField source="attributes.maxVal" label="Max. Value" />
          <ReferenceField label="Game (GameId)" source="attributes.gameId" reference="games" sortByOrder="DESC">
            <TextField source="attributes.name" />
            {" ("}
            <TextField source="id" />
            {")"}
          </ReferenceField>
          <SingleRelationRecord source="" icon={<LinkIcon />} name="Multi" path="/gameMultiParameter">
            <EditButton />
          </SingleRelationRecord>
        </Datagrid>
      </List>
      <p>
        * When a Game Parameter is the Type <b>other then "select"</b>, it is a Multi-Parameter. A Multi-Parameter has 0
        for Value, Step, Min. and Max. attributes here. Changing Type to "<b>radio or choice</b>" to create a
        Multi-Paramtemer.
      </p>
    </>
  );
};
export default GameParameters;
