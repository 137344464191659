import * as React from "react";
import MyPagination from "../MyPagination";
import { List, Datagrid, TextField, EditButton } from "react-admin";
import { ReferenceField } from "react-admin";

const GameCharts = () => {
  //
  return (
    <List pagination={<MyPagination />} perPage="50">
      <h3 style={{ marginLeft: "10px", marginTop: "-38px", width: "200px" }}>Game Charts</h3>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label="ChartId" />

        {/* <TextField source="attributes.gameId" label="GameId" /> */}
        <TextField source="attributes.month" label="Month" />
        <TextField source="attributes.aov" label="AOV" />
        <ReferenceField label="Game (GameId)" source="attributes.gameId" reference="games" sortByOrder="DESC">
          <TextField source="attributes.name" />
          {" ("}
          <TextField source="id" />
          {")"}
        </ReferenceField>
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default GameCharts;
