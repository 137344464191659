import * as React from "react";
import MyPagination from "../MyPagination";
import { List, Datagrid, TextField, EditButton } from "react-admin";
import { ReferenceField } from "react-admin";

const MultiGameParameters = () => {
  return (
    <List perPage={15} pagination={<MyPagination />}>
      <h3 style={{ marginLeft: "10px", marginTop: "-38px", width: "200px" }}>Game Multi-Parameters</h3>

      <Datagrid bulkActionButtons={false}>
        <TextField source="id" disabled label="Multi-Param Id" />
        <TextField source="attributes.name" label="Name" />
        <TextField source="attributes.label" label="Label" />
        <TextField source="attributes.value" label="Value" />
        <TextField source="attributes.priority" label="Priority" />
        <TextField source="attributes.parameterId" label="ParamId" />
        <TextField source="attributes.parameter" label="ParameterSeoName" />
        <ReferenceField
          label="Parameter (GameId)"
          source="attributes.parameterId"
          reference="paramaters"
          sortByOrder="DESC"
        >
          <TextField source="attributes.label" />
          {" ("}
          <TextField source="id" />
          {")"}
        </ReferenceField>
        <EditButton />
      </Datagrid>
    </List>
  );
};
export default MultiGameParameters;
