import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { HttpError } from 'react-admin';
var apiUrl = "";
var token = "";
const httpClient = fetchUtils.fetchJson;

const apiEndPoint = process.env.REACT_APP_SERVER;
const apiFuncEndPoint = process.env.REACT_APP_APIFUNCSERVER;

const dataProvider = function (url) {

  apiUrl = url;
  token = localStorage.getItem("token");

  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  const returnedObj = {
    getList: (resource, params) => {
      console.log('resource', resource)
      console.log('params', params)

      if (resource == 'FindGameAll') {
        apiUrl = apiFuncEndPoint;
      }
      else if (resource == 'quiz/uploadCharacter') {
        const url = window.location.hash;
        const urlParams = new URLSearchParams(url.split("?")[1]);
        const filter = JSON.parse(urlParams.get("filter"));
        const quizId = filter.quizId;
        if(quizId){
          return httpClient(decodeURIComponent(`${apiUrl}/${resource}/?quizId=${quizId}`), {
            headers: new Headers({ Authorization: localStorage.getItem("token") }),
          }).then(
            (response) => {
              if (response?.json?.error?.message) {
                return Promise.reject(
                  new HttpError(
                    response.json.error.message,
                    response.status,
                    response.json
                  )
                );
              }
              else {
                const transformedData = response?.json.data.map((item, index) => ({
                  id: index + 1,
                  ...item,
                }));
                const total = transformedData.length;
                return { data: transformedData, total };
              }
            }
          )
        }else{
          return Promise.resolve({ data: [], total: 0 });
        }
      } else if (resource == 'quiz/queryCharacter') {
        const url = window.location.hash;
        const urlParams = new URLSearchParams(url.split("?")[1]);
        const filter = JSON.parse(urlParams.get("filter"));
        const quizId = filter.quizId;
        if (quizId) {
          return httpClient(decodeURIComponent(`${apiUrl}/${resource}/?quizId=${quizId}`), {
            headers: new Headers({ Authorization: localStorage.getItem("token") }),
          }).then(({ headers, json }) => {
            if (json.data && (json.data.id || (json.data[0] && json.data[0].id))) {
              return {
                data: json.data,
                total: json.meta?.pagination ? parseInt(json.meta.pagination.total) : parseInt(json.data.length),
              };
            } else {
              return { data: [], total: 0 };
            }
          });
        } else {
          return Promise.resolve({ data: [], total: 0 });
        }
      } else if (resource == 'quiz/queryAttribute') {
        const url = window.location.hash;
        const urlParams = new URLSearchParams(url.split("?")[1]);
        const filter = JSON.parse(urlParams.get("filter"));
        const quizId = filter?.quizId ? filter?.quizId : params.quizId;
        const quizName = filter?.quizName; //给value question查询用
        let query = `${apiUrl}/${resource}/?quizId=${quizId}`
        if (quizId) {
          return httpClient(decodeURIComponent(`${quizName || params.quizId ? `${query}&IsEnable=true` : `${query}`}`), {
            headers: new Headers({ Authorization: localStorage.getItem("token") }),
          }).then(({ headers, json }) => ({

            data: json.data?.id || json.data[0]?.id ? json.data : json.data.map((item) => ({ ...item, id: item.gameId })),
            total: json.meta?.pagination ? parseInt(json.meta.pagination.total) : parseInt(json.data.length),
          }));
        }
      }
      else if (resource == 'quiz/queryValueQuestion') {
        const { page, perPage = 15 } = params.pagination;
        const { field, order = "ASC" } = params.sort;
        const query = {
          filters: "",
          gameId: "",
          paramid: "",
        };
        const _pagination = `?pageIndex=${page}&pageSize=${perPage}`;
        let _sortTemp = field.split(".").slice(-1)[0];
        const _sort = `&sort=${_sortTemp.charAt(0).toUpperCase() + _sortTemp.slice(1)}`;
        if (Object.keys(params.filter).length) {
          query.filters = params.filter.q && params.filter.q;
          query.gameId = params.filter.gameId && params.filter.gameId;
          query.quizId = params.filter.quizId && params.filter.quizId;
        }
        let _query = query.filters ? `&keyword=${query.filters}` : "";
        let _querySelect = query.gameId ? `&gameId=${query.gameId}` : "";
        _querySelect = query.quizId ? _querySelect + `&quizId=${query.quizId}` : _querySelect;
        const _order = `&order=${order}`;
        let geturl = `${apiUrl}/${resource}${_pagination}${_sort}${_order}${_query}${_querySelect}`;
        apiUrl = apiEndPoint

        return httpClient(decodeURIComponent(geturl), {
          headers: new Headers({ Authorization: localStorage.getItem("token") }),
        }).then(({ headers, json }) => {
          if (json.data && (json.data.id || (json.data[0] && json.data[0].id))) {
            return {
              data: json.data,
              total: json.meta?.pagination ? parseInt(json.meta.pagination.total) : parseInt(json.data.length),
            };
          } else {
            return { data: [], total: 0 };
          }
        });

      }
      else {
        apiUrl = apiEndPoint;
      }
      const { page, perPage = 15 } = params.pagination;
      const { field, order = "ASC" } = params.sort;

      const query = {
        filters: "",
        gameId: "",
        paramid: "",
      };

      const _pagination = `?pageIndex=${page}&pageSize=${perPage}`;

      let _sortTemp = field.split(".").slice(-1)[0];
      const _sort = `&sort=${_sortTemp.charAt(0).toUpperCase() + _sortTemp.slice(1)}`;

      if (Object.keys(params.filter).length) {
        query.filters = params.filter.q && params.filter.q;
        query.gameId = params.filter.gameId && params.filter.gameId;
        query.paramid = params.filter.paramid && params.filter.paramid;
      }

      let _query = query.filters ? `&keyword=${query.filters}` : "";

      let _querySelect = query.gameId ? `&gameId=${query.gameId}&type=1` : "";
      _querySelect = query.paramid ? _querySelect + `&paramid=${query.paramid}&type=1` : _querySelect;

      const _order = `&order=${order}`;
      console.log('resource', resource)

      let url = `${apiUrl}/${resource}${_pagination}${_sort}${_order}${_query}${_querySelect}`;
      apiUrl = apiEndPoint

      return httpClient(decodeURIComponent(url), {
        headers: new Headers({ Authorization: localStorage.getItem("token") }),
      }).then(({ headers, json }) => ({
        data: json.data?.id || json.data[0]?.id ? json.data : json.data.map((item) => ({ ...item, id: item.gameId })),
        total: json.meta?.pagination ? parseInt(json.meta.pagination.total) : parseInt(json.data.length),
      }));
    },

    getOne: (resource, params) =>
      httpClient(`${apiUrl}/${resource}/${params.id}`, {
        headers: new Headers({ Authorization: localStorage.getItem("token") }),
      }).then(({ json }) => ({
        data: json.data,
      })),

    getMany: (resource, params) => {
      const urlAddr = window.location.hash;
      const urlParams = new URLSearchParams(urlAddr.split("?")[1]);
      const filter = JSON.parse(urlParams.get("filter"));
      const multiGameId = filter.multiGameId;

      var url = `${apiUrl}/${resource}?order="ASC"&sort="Id"`;
      if (resource === "paramaters") {
        url += `&gameId=${multiGameId}&type=1`;
      } else if (resource === 'quiz/queryCharacterValueForAttribute') {
        url = `${apiUrl}/${resource}/?attributeId=${params.attributeId}`;
        return httpClient(url, {
          headers: new Headers({ Authorization: localStorage.getItem("token") }),
        }).then(({ json }) => ({
          data: json.data.map((value, index) => ({ id: index, value })),
        }));
      }else if (resource === 'quiz/queryQuiz') {
        url = `${apiUrl}/${resource}?order="ASC"&sort="Id"&pageSize=10000`;
      }
      return httpClient(url, {
        headers: new Headers({ Authorization: localStorage.getItem("token") }),
      }).then(({ json }) => ({
        data: json.data.length ? json.data : [json.data],
      }));
    },

    getManyReference: (resource, params) => {
      const { page, perPage = 15 } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        filters: "",
        gameId: "",
      };
      const _pagination = `?pagination[page]=${page}&pagination[pageSize]=${perPage}`;

      const _sort = `&sort=${field.split(".").slice(-1)[0]}`;

      if (Object.keys(params.filter).length) {
        query.filters = params.filter.q && params.filter.q;
        query.gameId = params.filter.gameId && params.filter.gameId;
      }

      const _query = query.filters ? `&filters[name][$contains]=${query.filters}` : "";
      const _querySelect = query.gameId ? `&filters[gameId]=${query.gameId}` : "";

      const _order = `:${order}`;

      const url = `${apiUrl}/${resource}${_pagination}${_sort}${_order}${_query}${_querySelect}`;

      return httpClient(decodeURIComponent(url), {
        headers: new Headers({ Authorization: localStorage.getItem("token") }),
      }).then(({ headers, json }) => ({
        data: json.data.length ? json.data : [json.data],
        total: json.meta.pagination && parseInt(json.meta.pagination.total),

      }));
    },

    update: (resource, params) => {
      var url;
      console.log('resource', resource);
      console.log('update', params);
      let { attributes: dataNew } = params.data;

      if (resource === "game-links") {
        url = `${apiUrl}/${resource}/${params.id}`;
      }else if(resource ==='paramaters'){
        url = `${apiUrl}/${resource}/${params.id}`;
        if(params.data.attributes.type ==='choice' || params.data.attributes.type ==='radio'){
          const paramData = {
            game: params.data.attributes.game,
            gameId: params.data.attributes.gameId,
            seoName: params.data.attributes.seoName,
            label: params.data.attributes.label,
            priority: params.data.attributes.priority,
            type: params.data.attributes.type,
            multis: params.data.attributes.multis,
          }
          dataNew = paramData
        }
        console.log('dataNew',dataNew)
      }else if (resource === "quiz/queryQuiz") {
        url = `${apiUrl}/quiz/modifyQuiz/`;
        if (params.data.landingPageOGImage) {
          const convertedData = {
            ...params.data.attributes,
            landingPageMetaDesciption: stripHtmlTags(params.data.attributes.landingPageMetaDesciption),
            landingPageTopParagraph: stripHtmlTags(params.data.attributes.landingPageTopParagraph),
            // landingPageButtomParagrapha: stripHtmlTags(params.data.attributes.landingPageButtomParagrapha),
            resultPageTopParagraph: stripHtmlTags(params.data.attributes.resultPageTopParagraph),
            landingPageOGImage: params.data.landingPageOGImage
          };
          dataNew = convertedData;
        } else {
          const convertedData2 = {
            ...params.data.attributes,
            landingPageMetaDesciption: stripHtmlTags(params.data.attributes.landingPageMetaDesciption),
            landingPageTopParagraph: stripHtmlTags(params.data.attributes.landingPageTopParagraph),
            // landingPageButtomParagrapha: stripHtmlTags(params.data.attributes.landingPageButtomParagrapha),
            resultPageTopParagraph: stripHtmlTags(params.data.attributes.resultPageTopParagraph),
          };
          dataNew = convertedData2;
        }
      } else if (resource === "quiz/queryRandomQuestion") {
        url = `${apiUrl}/quiz/modifyRandomQuestion/`;
        
      } else if (resource === "quiz/queryValueQuestion") {
        url = `${apiUrl}/quiz/modifyValueQuestion/`;

        const convertedData = {
          questionId: params.data.attributes.questionId,
          attributeId: params.data.attributes.attributeId,
          questionName: params.data.attributes.questionName,
          questionOptions: params.data.attributes.questionOptions.map(option => ({
            questionOptionName: option.questionOptionName,
            attributeCharacterValues: option.attributeCharacterValues,
          })).filter(option => option.questionOptionName && option.attributeCharacterValues.length > 0),
        };

        dataNew = convertedData
      }
      else if (resource === "playerCount/games") {
        url = `${apiUrl}/${resource}/${params.id}`;
        let gameId,
          gameSeo,
          gameName,
          developer,
          released,
          totalSubscriber,
          gameDescripint,
          steamAppId,
          googleQuery,
          googleCoefficient,
          platform,
          genres;
        ({
          gameId,
          gameSeo,
          gameName,
          developer,
          released,
          totalSubscriber,
          gameDescripint,
          steamAppId,
          googleQuery,
          googleCoefficient,
          platform,
          genres,
        } = dataNew);

        dataNew = {
          gameId,
          gameSeo,
          gameName,
          developer,
          released,
          totalSubscriber,
          gameDescripint,
          steamAppId,
          googleQuery,
          googleCoefficient,
          platform,
          genres,
        };
      }else {
        url = `${apiUrl}/${resource}/${params.id}`;
      }

      return httpClient(url, {
        method: "PUT",
        headers: new Headers({ Authorization: localStorage.getItem("token") }),
        body: JSON.stringify({ data: dataNew }),
      })
        .then(
          (response) => {
            if (response?.json?.error?.message) {
              return Promise.reject(
                new HttpError(
                  response.json.error.message,
                  response.status,
                  response.json
                )
              );
            }
            else
              return { data: { ...params.data, id: params.id } }
          }
        )
    },

    updateMany: (resource, params) => {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      };
      return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        method: "PUT",
        headers: new Headers({ Authorization: localStorage.getItem("token") }),
        body: JSON.stringify({ data: params.data }),
      }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
      console.log('create', params)
      console.log('resource', resource)
      if (resource === "playerCount/games") {
        params.data = {
          ...params.data,
          gameId: parseInt(params.data.gameName.split('/')[0]),
          gameName: params.data.gameName.split('/')[1],
          gameSeo: params.data.gameName.split('/')[2],
          totalSubscriber: parseInt(params.data.totalSubscriber) || 0,
          steamAppId: parseInt(params.data.steamAppId) || null,
          googleCoefficient: parseFloat(params.data.googleCoefficient),
        }
      }  else if (resource === "games") {
        params.data = {
          ...params.data,
          name: params.data.name.split('/')[1],
        }
      }else if (resource === "links") {
        params.data = {
          ...params.data,
          gameId: parseInt(params.data.gameId.split('/')[0]),
          game: params.data.gameId.split('/')[1],
          googleCoefficient: parseFloat(params.data.googleCoefficient),
        }
      } else if (resource === "gameStat") {
        params.data = {
          ...params.data,
          gameId: parseInt(params.data.gameId.split('/')[0]),
          game: params.data.gameId.split('/')[1],
        }
      }
      else if (resource === "paramaters") {
        params.data = {
          ...params.data,
          multis: []
        }
      } else if (resource === "quiz/queryQuiz") {
        params.data = {
          ...params.data,
          landingPageMetaDesciption: stripHtmlTags(params.data.landingPageMetaDesciption),
          landingPageTopParagraph: stripHtmlTags(params.data.landingPageTopParagraph),
          // landingPageButtomParagrapha: stripHtmlTags(params.data.landingPageButtomParagrapha),
          resultPageTopParagraph: stripHtmlTags(params.data.resultPageTopParagraph),
          gameId: parseInt(params.data.gameName.split('/')[0]),
          gameName: params.data.gameName.split('/')[1],
          gameSeoName: params.data.gameName.split('/')[2],

        }
        resource = 'quiz/createQuiz'
      }
      else if (resource === "quiz/queryRandomQuestion") {
        const questionOptions = [];
        for (let i = 1; i <= 6; i++) {
          const optionKey = `questionOptions${i}`;
          const optionValue = params.data[optionKey];
          if (optionValue !== undefined) {
            questionOptions.push(optionValue);
          }
        }
        params.data = {
          questionName: params.data.questionName,
          questionOptions
        }
        resource = 'quiz/createRandomQuestion'
      } else if (resource === "quiz/uploadCharacter") {
        const url = window.location.hash;
        const urlParams = new URLSearchParams(url.split("?")[1]);
        const filter = JSON.parse(urlParams.get("filter"));
        const quizId = filter.quizId;
        const file = params.data.characterFile.rawFile;
        const formData = new FormData();
        formData.append("characterFile", file, file.name);
        formData.append("quizId", quizId);
        return httpClient(`${apiUrl}/${resource}`, {
          method: "POST",
          headers: new Headers({ Authorization: localStorage.getItem("token") }),
          body: formData,
        }).then((response) => {
          if (response?.json?.success === false) {
            return Promise.reject(
              new HttpError(
                response?.json?.error?.message ,
                response?.status,
                response?.json
              )
            );
          } else {
            if (response?.json?.error?.message) {
              new HttpError(
                response?.json?.error?.message,
                response?.status,
                response?.json
              )
            }
            return { data: { ...params.data, id: response?.json } };
          }
        });
      } else if (resource === "quiz/uploadAzureImage") {
        return httpClient(`${apiUrl}/${resource}`, {
          method: "POST",
          headers: new Headers({ Authorization: localStorage.getItem("token") }),
          body: params.data,
        }).then(
          (response) => {
            if (response?.json?.error?.message) {
              return Promise.reject(
                new HttpError(
                  response.json.error.message,
                  response.status,
                  response.json
                )
              );
            }
            else {
              return { data: { ...params.data, id: response.json } }
            }
          }
        )
      } else if (resource === "quiz/queryAttribute") {
        resource = 'quiz/modifyAttributePoints'
      } else if (resource === "quiz/queryValueQuestion") { //Add Value Question
        const url = window.location.hash;
        const urlParams = new URLSearchParams(url.split("?")[1]);
        const filter = JSON.parse(urlParams.get("filter"));
        const quizId = filter.quizId;
        const convertedData = {
          quizId: parseInt(quizId),
          attributeId: parseInt(params.data.attributeId),
          questionName: params.data.questionName,
          questionOptions: params.data.questionOptions.filter(
            (option) => option.questionOptionName && option.attributeCharacterValues.length > 0
          ),
        };

        console.log('convertedData', convertedData)
        resource = 'quiz/createValueQuestion'
        return httpClient(`${apiUrl}/${resource}`, {
          method: "POST",
          headers: new Headers({ Authorization: localStorage.getItem("token") }),
          body: JSON.stringify({ data: convertedData }),
        }).then(
          (response) => {
            if (response?.json?.error?.message) {
              return Promise.reject(
                new HttpError(
                  response.json.error.message,
                  response.status,
                  response.json
                )
              );
            }
            else
              return { data: { ...params.data, id: params.id } }
          }
        )
      }
      return httpClient(`${apiUrl}/${resource}`, {
        method: "POST",
        headers: new Headers({ Authorization: localStorage.getItem("token") }),
        body: JSON.stringify({ data: params.data }),
      }).then(
        (response) => {
          if (response?.json?.error?.message) {
            return Promise.reject(
              new HttpError(
                response.json.error.message,
                response.status,
                response.json
              )
            );
          }
          else
            return { data: { ...params.data, id: params.id } }
        }
      )
    },

    delete: (resource, params, fromMany) =>
      httpClient(`${apiUrl}/${resource}/${params.id}`, {
        headers: new Headers({ Authorization: localStorage.getItem("token") }),
        method: "DELETE",
      }).then(({ json }) => {
        if (fromMany) {
          return { data: [json.data] };
        }
        return { data: json.data };
      }),

    deleteMany: (resource, params) => {
      if (params.ids.length === 1) {
        return returnedObj.delete(resource, { id: params.ids[0] }, 1);
      } else {
        const query = {
          filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
          headers: new Headers({ Authorization: localStorage.getItem("token") }),
          method: "DELETE",
        }).then(({ json }) => ({ data: json }));
      }
    },
  };
  return returnedObj;
};

export default dataProvider;