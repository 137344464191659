import * as React from "react";
import { useMediaQuery } from "@mui/material";
import MyPagination from "../MyPagination";
import { Link as Link2 } from "react-router-dom";
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  useRecordContext,
  ListButton,
} from "react-admin";

import LinkIcon from "../icons/LinkIcon";
import ShowChart from "../icons/ShowChart";
import FormatListNumbered from "../icons/FormatListNumbered";

const GameListTitle = () => {
  const record = useRecordContext();
  return <span>Post {record ? `"${record.title}"` : "123"}</span>;
};

const gameFilters = [<TextInput source="q" label="Search" alwaysOn resettable />];
const SingleRelationRecord = ({ path, name, icon }) => {
  const record = useRecordContext();
  return (
    <ListButton
      icon={icon}
      label={name}
      color="primary"
      sx={{ marginLeft: "-10px" }}
      component={Link2}
      to={{
        pathname: path,
        search: `filter=${JSON.stringify({ gameId: record.id })}`,
      }}
    ></ListButton>
  );
};
const GameList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List title={<GameListTitle />} filters={gameFilters} pagination={<MyPagination />} perPage={15}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.title}
          secondaryText={(record) => (
            <>
              <div>
                <b>Id:</b> {record.id}
              </div>
              <div>
                <b>Name:</b> {record.attributes.name}
              </div>
              <div>
                <b>SeoName:</b> {record.attributes.seoName}
              </div>

              <div style={{ display: "flex" }}>
                <div>
                  <b>Links:</b>
                  <SingleRelationRecord source="" label="1" name="LINKS" path="/links" />
                </div>

                <div>
                  <b>Chart:</b>
                  <SingleRelationRecord source="" label="2" name="CHARTS" path="/gameStat" />
                </div>

                <div>
                  <b>Parameters:</b>
                  <SingleRelationRecord source="" label="3" name="PARAMETERS" path="/paramaters" />
                </div>
              </div>
            </>
          )}
          linkType={(record) => (record.attributes.seoName ? "edit" : "show")}
          rowStyle={() => ({
            border: "1px solid #eee",
          })}
        />
      ) : (
        <Datagrid perPage={15} bulkActionButtons={false}>
          <TextField source="id" label="id" />
          <TextField source="attributes.name" label="name" />
          <TextField source="attributes.seoName" label="SeoName" />
          <SingleRelationRecord source="" icon={<LinkIcon />} name="LINKS" path="/links" />
          <SingleRelationRecord source="" icon={<ShowChart />} name="CHARTS" path="/gameStat" />
          <SingleRelationRecord source="" icon={<FormatListNumbered />} name="PARAMETERS" path="/paramaters" />
          {/* <SingleRelationRecord source="" label="" name="MULTI-PARAMETERS" path="/multi-parameter-items" /> */}
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};
export default GameList;
