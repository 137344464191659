import * as React from "react";
import { useMediaQuery, IconButton } from "@mui/material";
import { Link as Link2 } from "react-router-dom";
import MyPagination from "../MyPagination";
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  ReferenceField,
  useRecordContext,
  ListButton,
} from "react-admin";
import StatusSwitcher from "./StatusSwitcher";
import SingleRelationRecord from "../common/SingleRelationRecord.js";
import { AddCircleOutline } from "@mui/icons-material";

const gameFilters = [<TextInput source="q" label="Search" alwaysOn resettable />];

const CharacterButton = () => {
  const record = useRecordContext();
  return record ? (
    <div>
      <ListButton
        icon={null}
        label={`${record.attributes.characterCount}`}
        color="primary"
        sx={{ marginLeft: "-10px" }}
        component={Link2}
        to={{
          pathname: "/quiz/queryCharacter",
          search: `filter=${JSON.stringify({ gameId: record.attributes.gameId, quizId: record.attributes.quizId })}`,
        }}
      />
    </div>
  ) : null;
};

const AttributeButton = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  const { attributeCount, gameId, quizId } = record.attributes;
  if (attributeCount === 0) {
    return (
      <div>
        <a color="error" style={{ color: "red" }}>
          {attributeCount}
        </a>
        <IconButton
          component={Link2}
          to={{
            pathname: "/quiz/uploadCharacter",
            search: `filter=${JSON.stringify({ gameId: gameId, quizId: quizId })}`,
          }}
        >
          <AddCircleOutline style={{ color: "#1976d2" }} />
        </IconButton>
      </div>
    );
  }
  return (
    <div>
      <ListButton
        icon={null}
        label={`${attributeCount}`}
        color="primary"
        sx={{ marginLeft: "-10px" }}
        component={Link2}
        to={{
          pathname: "/quiz/queryAttribute",
          search: `filter=${JSON.stringify({ gameId: record.attributes.gameId, quizId: record.attributes.quizId })}`,
        }}
      />
    </div>
  );
};

const ValuedButton = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  const { valuedQuestionCount, gameId, quizId, quizName } = record.attributes;
  if (valuedQuestionCount === 0) {
    return (
      <div>
        <a color="error" style={{ color: "red" }}>
          {valuedQuestionCount}
        </a>
        <IconButton
          component={Link2}
          to={{
            pathname: "/quiz/queryValueQuestion/create",
            search: `filter=${JSON.stringify({ gameId: gameId, quizId: quizId, quizName: quizName })}`,
          }}
        >
          <AddCircleOutline style={{ color: "#1976d2" }} />
        </IconButton>
      </div>
    );
  }
  return (
    <div>
      <ListButton
        icon={null}
        label={`${valuedQuestionCount}`}
        color="primary"
        sx={{ marginLeft: "-10px" }}
        component={Link2}
        to={{
          pathname: "/quiz/queryValueQuestion",
          search: `filter=${JSON.stringify({ gameId: record.attributes.gameId, quizId: record.attributes.quizId })}`,
        }}
      />
    </div>
  );
};

const QuizList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List filters={gameFilters} pagination={<MyPagination />} perPage={15}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.title}
          secondaryText={(record) => (
            <>
              <div>
                <b>Id:</b> {record.id}
              </div>
              <div>
                <b>Name:</b> {record.attributes.gameName}
              </div>
              <div>
                <b>Quiz Name:</b>
                <ReferenceField label="Quiz Name" source="attributes.quizId" reference="quiz/queryQuiz" sortByOrder="DESC">
                  {record.attributes.quizName}
                </ReferenceField>
              </div>
              <div>
                <b>Slug:</b> {record.attributes.landingPageSlug}
              </div>
              <div>
                <b>Character:</b> {record.attributes.characterCount}
              </div>
              <div>
                <b>Attribute:</b> {record.attributes.attributeCount}
              </div>
              <div>
                <b>Acitive Valued Questions:</b> {record.attributes.valuedQuestionCount}
              </div>
              <div style={{ display: "flex" }}>
                <div >
                  <b>Character Table:</b>
                  <SingleRelationRecord source="" name="Character Table" path="/quiz/uploadCharacter" />
                </div>
              </div>
              <StatusSwitcher name="pageStatus" label="pageStatus" isMobile={true} />
            </>
          )}
          tertiaryText={(record) =>
            record.attributes.updateDateTime &&
            "Last updated: " + new Date(record.attributes.updateDateTime).toLocaleDateString("medium")
          }
          linkType={(record) => (record.attributes.seoName ? "edit" : "show")}
          rowStyle={(record) => ({
            backgroundColor: record.attributes.name ? "white" : "white",
            border: "1px solid #eee",
          })}
        />
      ) : (
        <Datagrid perPage={15} bulkActionButtons={false}>
          <TextField source="attributes.gameName" label="Game" />
          <ReferenceField label="Quiz Name" source="attributes.quizId" reference="quiz/queryQuiz">
            <TextField source="attributes.quizName" />
          </ReferenceField>
          <TextField source="attributes.landingPageSlug" label="Slug" />
          <span label="Character">
            <CharacterButton />
          </span>
          <span label="Attribute">
            <AttributeButton />
          </span>
          <span label="Acitive Valued Questions">
            <ValuedButton />
          </span>
          <StatusSwitcher name="isEnabled" label="Page Status" />
          <SingleRelationRecord source="" name="Character Table" path="/quiz/uploadCharacter">
            <EditButton label="Quiz Setting" />
          </SingleRelationRecord>
        </Datagrid>
      )}
    </List>
  );
};
export default QuizList;
