import * as React from "react";
import { useMediaQuery } from "@mui/material";
import MyPagination from "../MyPagination";
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    EditButton,
    TextInput,
    BooleanInput,
    useCreate,
    useNotify,
    useRefresh,
    Form,
} from "react-admin";
import StatusSwitcher from "./StatusSwitcher";
import { useRecordContext } from "ra-core";
import { useState } from "react";

const gameFilters = [<TextInput source="q" label="Search" alwaysOn resettable />];

const StatusComponent = () => {
    const record = useRecordContext(); 
    const notify = useNotify();
    const refresh = useRefresh();
    const [status, setStatus] = useState(true);

    const [create, { isLoading, error }] = useCreate(
        `quiz/modifyRandomQuestionStatus`,
        {
            data: { questionId: record.attributes.questionId, isEnabled: status },
        },
        {
            onSuccess: (data) => {
                notify("Update Success");
                refresh();
            },
            onError: (error) => {
                notify(`Update Failed: ${error.message}`, { type: "warning" });
            },
        }
    );

    const handleSwitchChange = (e) => {
        handleSubmit(e.target.checked);
    };

    const handleSubmit = (status) => {
        setStatus(status);
        create("quiz/modifyRandomQuestionStatus", {
            data: { questionId: record.attributes.questionId, isEnabled: status },
        });
    };
    return (
        <BooleanInput
            source="attributes.isEnabled"
            label=""
            sx={{ flexDirection: "row" }}
            onChange={handleSwitchChange}
        />
    );
};

const RandomQuestionList = () => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    return (
        <List filters={gameFilters} pagination={<MyPagination />} perPage={15}>
            {isSmall ? (
                <SimpleList
                    primaryText={(record) => record.title}
                    secondaryText={(record) => (
                        <>
                            <div>
                                <b>Question:</b> {record.attributes.questionName}
                            </div>
                            <StatusSwitcher name="pageStatus" label="pageStatus" isMobile={true} />
                        </>
                    )}
                    tertiaryText={(record) =>
                        record.attributes.updateDateTime &&
                        "Last updated: " + new Date(record.attributes.updateDateTime).toLocaleDateString("medium")
                    }
                    linkType={(record) => (record.attributes.seoName ? "edit" : "show")}
                    rowStyle={(record) => ({
                        backgroundColor: record.attributes.name ? "white" : "white",
                        border: "1px solid #eee",
                    })}
                />
            ) : (
                <Datagrid perPage={15} bulkActionButtons={false}>
                    <TextField source="attributes.questionName" label="Question" />
                    <Form label="Page Status">
                        <StatusComponent />
                    </Form>
                    <EditButton />
                </Datagrid>
            )}
        </List>
    );
};
export default RandomQuestionList;
