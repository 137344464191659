import * as React from "react";
import { useLogin, useNotify } from "react-admin";
import { Box, Card, Button, CardActions, Avatar } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Logo from "./Logo";

const MyLoginPageCustom = ({ theme }) => {
  const login = useLogin();
  const notify = useNotify();

  const { instance } = useMsal();

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then((tokenResponse) => {
        console.log('tokenResponse',tokenResponse)
        let { tokenType, accessToken, account } = tokenResponse;
        let username = account.username;
        let token = `${tokenType} ${accessToken}`;

        login({ username, token }).catch(() => notify("Invalid email"));
      })
      .catch((e) => {
        console.log('e',e);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        minHeight: "100vh",
        alignItems: "center",
        // background: "url(https://source.unsplash.com/random/1600x900)",
        backgroundColor: "#292e43",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginTop: "260px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo classNameCustom="paLogoLogin" />
        <h1 style={{ color: "#fff", fontSize: "20px", marginBottom: "30px" }}>Game Tools Admin | PlayAuctions</h1>
      </Box>

      <Card sx={{ minWidth: 300 }}>
        <Box
          sx={{
            margin: "1em",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Avatar sx={{ bgcolor: "secondary.main" }}>
            <LockIcon />
          </Avatar>
        </Box>
        <CardActions sx={{ padding: "0 1em 1em 1em" }}>
          <Button variant="contained" type="submit" color="primary" fullWidth onClick={() => handleLogin()}>
            Microsoft SignIn
          </Button>
        </CardActions>
      </Card>
      {/* <p style={{ color: "#F3F3F3", width: "500px", margin: "20px auto", fontSize: "14px", textAlign: "center" }}>
        If you are having trouble logging in, please clear cache by "Ctrl + F5".
      </p> */}
    </Box>
  );
};

export default MyLoginPageCustom;
