import { Layout } from "react-admin";
import MyAppBar from "./MyAppBar";
import MyMenu from "./MyMenu";
import { ReactQueryDevtools } from 'react-query/devtools'

const MyLayout = (props) => {
  const { isloginpage } = props;
  return (
    <>
      {isloginpage && isloginpage ? (
        <Layout {...props} appBar={MyAppBar} />
      ) : (
        <Layout {...props} appBar={MyAppBar} menu={MyMenu} />
      )}
      <ReactQueryDevtools />
    </>
  );
};

export default MyLayout;
