import { Menu } from "react-admin";
import QuizIcon from "@mui/icons-material/Quiz";
import CalculateIcon from "@mui/icons-material/Calculate";
import Addchart from "@mui/icons-material/Addchart";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ArticleIcon from '@mui/icons-material/Article';
import { createStyles, makeStyles } from '@mui/styles';
import SubMenu from './MySubMenu';


const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      '&.selected': {
        backgroundColor: theme.palette.grey[300],
        fontWeight: 'bold !important'
      },
    },
  })
);


const MyMenu = (props) => {
  const classes = useStyles();
  return (
    <Menu>
      <Menu.DashboardItem />
      <Menu.Item to="/games" primaryText="Calculator" leftIcon={<CalculateIcon />} classes={{ root: classes.menuItem }} />
      <Menu.Item to="/playerCount/games" primaryText="Player Count" leftIcon={<Addchart />} />
      {/* <Menu.Item to="/quiz/queryQuiz" primaryText="Game Quiz" leftIcon={<QuizIcon />} /> */}
      <SubMenu primaryText="Game Quiz" leftIcon={<QuizIcon />} >
      <Menu.Item
        to="/quiz/queryQuiz" primaryText="Quiz List"
        classes={{ root: classes.menuItem }}
        style={{marginLeft:25}}
      />
      <Menu.Item
        to={{
          pathname: '/quiz/uploadCharacter',
          search: `filter=${JSON.stringify({ gameId: '', quizId: '' })}`,
        }}
        primaryText="Charater Table Upload"
        selected={window.location.href.split('?')[0].split('#')[1] === "/quiz/uploadCharacter"}
        classes={{ root: classes.menuItem }}
        style={{marginLeft:25}}
      />
      <Menu.Item
       to={{
        pathname: '/quiz/queryValueQuestion',
        search: `filter=${JSON.stringify({ gameId: '', quizId: '' })}`,
      }}
        primaryText="Valued Question List"
        style={{marginLeft:25}}
      />
      <Menu.Item to="/quiz/queryRandomQuestion" primaryText="Random Question Pool"  style={{marginLeft:25}}/>

      </SubMenu>
      {/* <Menu.Item
        to="/game-links"
        primaryText="Game Links"
        leftIcon={<LabelIcon />}
      />
      <Menu.Item
        to="/game-charts"
        primaryText="Game Charts"
        leftIcon={<LabelIcon />}
      />
      <Menu.Item
        to="/parameters"
        primaryText="Game Parameters"
        leftIcon={<LabelIcon />}
      />
      <Menu.Item
        to="/multi-parameter-items"
        primaryText="Game Multi Parameters"
        leftIcon={<LabelIcon />}
      /> */}
    </Menu>
  );
};
export default MyMenu;
